import React, { useEffect, useState } from "react";
import socketIO from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import { chatList, getToken, getUser } from "../store/slices/userSlice";
import moment from "moment";
import { useLocation } from "react-router-dom";
import Chatbox from "./Chatbox";

const Chat = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [chat, setChat] = useState([]);
  const user = useSelector(getUser);
  const [toggle, setToggle] = useState(false);
  const [boxDetail, setBoxDetail] = useState("");
  console.log(location?.state);
  const messages = async () => {
    try {
      const response = await dispatch(chatList()).unwrap();
      setChat(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  const chatBoxDetails = (item) => {
    setBoxDetail({
      sender: item?.sender_id,
      receiver: item?.receiver_id,
    //   sender_id: item?.sender_id,
    //   receiver_id: item?.receiver_id,
      sender_id: item?.sender_id?._id,
      receiver_id: item?.receiver_id?._id,
    //   product_id: item?.product_id?._id,
      user: user,
    });
  };
  useEffect(() => {
    messages();
  }, [boxDetail]);
console.log("box details", boxDetail)
console.log("chat list", chat)
  return (
    <>
          <div className="main-panel">
        <div className="content-wrapper2">
          {/* <div className="page-header">
            <h3 className="page-title">
              <span className="page-title-icon bg-gradient-info text-white me-2">
                <i className="mdi mdi-chat" />
              </span> Chat
            </h3>
          </div> */}
      <section className="content">
        <section className="block">
          <div className="container">
            <div className="row">
              <div className="col-md-5 col-lg-5 col-xl-4">
                {/*============ Section Title===========================================================*/}
                <div className="section-title clearfix">
                  {/* <h3>People</h3> */}
                </div>
                <div id="messaging__chat-list" className="messaging__box">

                  <div className="messaging__content">
                    <ul className="messaging__persons-list">
                      {chat?.length > 0 ? chat.map((item, i) => (
                        <li onClick={(e) => chatBoxDetails(item)}>
                          <a href="#!" className="msgItem messaging__person">
                            <div className="imgBox">
                              <img
                                src={
                                  item?.sender_id?._id == user?._id &&
                                  item?.receiver_id?.user_image
                                    ? `${process.env.REACT_APP_IMAGEURL}${item?.receiver_id?.user_image}`
                                    : item?.sender_id?._id == user?._id &&
                                      !item?.receiver_id?.user_image
                                    ? "/assets/images/user-default.png"
                                    : item?.receiver_id?._id == user?._id &&
                                      item?.sender_id?.user_image
                                    ? `${process.env.REACT_APP_IMAGEURL}${item?.sender_id?.user_image}`
                                    : "/assets/images/user-default.png"
                                }
                                alt="img"
                              />
                            </div>
                            <div className="textBox content">
                              <h5>
                                {item?.sender_id?._id == user?._id &&
                                item?.receiver_id?.firstName + ' ' + item?.receiver_id?.lastName
                                  ? item?.receiver_id?.firstName + ' ' + item?.receiver_id?.lastName
                                  : item?.sender_id?._id == user?._id &&
                                    !item?.receiver_id?.firstName + ' ' + item?.receiver_id?.lastName
                                  ? "Eco User"
                                  : item?.receiver_id?._id == user?._id &&
                                    item?.sender_id?.firstName + ' ' + item?.sender_id?.lastName
                                  ? item?.sender_id?.firstName+ ' ' + item?.sender_id?.lastName
                                  : "Eco User"}
                              </h5>
                              <p className="desc1">{item?.message}</p>
                              <small>
                                {moment(item?.createdAt)
                                  .startOf("hour")
                                  .fromNow()}
                              </small>
                            </div>
                          </a>
                        </li>
                      )) : <><div className="noChat content"><p>No Chat Found</p></div></>}
                    </ul>
                    {/*end messaging__persons-list*/}
                  </div>
                  {/*messaging__content*/}
                </div>
                {/*end section-title*/}
              </div>
              {/*end col-md-3*/}
              <div className="col-md-7 col-lg-7 col-xl-8">
                {/*============ Section Title===========================================================*/}
                <div className="section-title clearfix">
                  {/* <h3>Message Window</h3> */}
                </div>
                {/*end section-title*/}
                <div id="messaging__chat-window" className="messaging__box">
                  <div className="messaging__header">
                    <div className="headerLeft1">
                      <div className="imgBox">
                        <img
                          src={
                          boxDetail?.sender?._id == user?._id &&
                                boxDetail?.receiver?.user_image
                              ? `${process.env.REACT_APP_IMAGEURL}${boxDetail?.receiver?.user_image}`
                              : boxDetail?.sender?._id == user?._id &&
                                !boxDetail?.receiver?.user_image
                              ? "/assets/images/user-default.png"
                              : boxDetail?.receiver?._id == user?._id &&
                                boxDetail?.sender?.user_image
                              ? `${process.env.REACT_APP_IMAGEURL}${boxDetail?.sender?.user_image}`
                              : "/assets/images/user-default.png"
                          }
                          alt="img"
                        />
                      </div>
                      <div className="textBox content">
                        <h5>
                          { boxDetail?.sender?._id == user?._id &&
                              boxDetail?.receiver?.firstName
                                ? boxDetail?.receiver?.firstName + " " + boxDetail?.receiver?.lastName
                                : boxDetail?.sender?._id == user?._id &&
                                  !boxDetail?.receiver?.firstName + " " + boxDetail?.receiver?.lastName
                                ? "Eco User"
                                : boxDetail?.receiver?._id == user?._id &&
                                  boxDetail?.sender?.firstName + " " + boxDetail?.sender?.lastName
                                ? boxDetail?.sender?.firstName + " " + boxDetail?.sender?.lastName
                                : "Eco User"} 
                        </h5>
                      </div>
                    </div>
                    <div className="headerLeft1">
                      <div className="textBox content">
                        <h5>Admin</h5>
                      </div>
                      <div className="imgBox">
                        <img src="/assets/images/user-default.png" alt="img" />
                      </div>
                    </div>
                  </div>

                  <Chatbox
                    sender_id={
                      boxDetail?.sender_id ? boxDetail?.sender_id : user?._id
                    }
                    receiver_id={
                      boxDetail?.receiver_id
                    }
                    // product_id={
                    //   boxDetail?.product_id
                    //     ? boxDetail?.product_id
                    //     : location?.state?._id
                    // }
                    user={boxDetail?.user ? boxDetail?.user : user}
                    chatList={messages}
                  />
                </div>
              </div>
              {/*end col-md-9*/}
            </div>
            {/*end row*/}
          </div>
          {/*end container*/}
        </section>
        {/*end block*/}
      </section>
      </div>
      </div>
    </>
  );
};

export default Chat;

// import moment from "moment/moment";
// import React, { useCallback, useEffect, useRef, useState } from "react";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// // import { blockUnBlock, chatFile } from "../../store/featureActions";
// import { useDispatch, useSelector } from "react-redux";
// import Modal from "react-modal";
// // import { getProfile } from "../../store/slices/userSlice";
// // import { getMessagesEmit, getSocket, sendMessageEmit } from "../../store/slices/socketSlice";
// import { toast } from 'react-toastify';
// import ImageViewer from "react-simple-image-viewer";

// const Chat = () => {
//     const location = useLocation();
//     const profile = 'profile';
//     // const profile = useSelector(getProfile);
//     const userId = location?.state?.userId;
//     const sender_id = profile?._id;
//     const receiver_id = location?.state?.userId?._id;
//     const [message, setMessage] = useState([]);
//     const [currentMessage, setCurrentMessage] = useState("");
//     // const socket = useSelector(getSocket);
//     const lastMessageRef = useRef(null);
//     const [loader, setLoader] = useState(false);
//     const [toggleBlock, setToggleBlock] = useState(false);
//     const [showPicker, setShowPicker] = useState(false);
//     const [activeUsers, setActiveUsers] = useState();
//     const [currentImage, setCurrentImage] = useState(0);
//     const [modalImages, setmodalImages] = useState(false);
//     const navigate = useNavigate();
//     const dispatch = useDispatch();
  
//     const openImageViewer = useCallback((images, index) => {
//       setCurrentImage(index);
//       setmodalImages(images);
//     }, []);
  
//     function closeModal() {
//       setToggleBlock(false);
//       setActiveUsers(null);
//       setCurrentImage(0);
//       setmodalImages(false);
//     }
  
//     const handleChange = async (e) => {
//       if (e?.target?.files?.length > 0) {
//         var formData = new FormData();
//         Array.from(e?.target?.files)?.map((item, i) => {
//           formData.append("chatFile", item);
//         });
//         try {
//           let payload = {
//             body: formData,
//             params: false,
//             isToast: false,
//           };
//         //   const response = await dispatch(chatFile(payload)).unwrap();
//           const response = await 'response';
//           try {
//             let payload = {
//               sender_id: sender_id,
//               receiver_id: receiver_id,
//               files: response?.data?.files,
//             };
//             // dispatch(sendMessageEmit(payload));
//           } catch (error) {
//             console.log(error);
//           }
//         } catch (rejectedValueOrSerializedError) {
//           console.log(rejectedValueOrSerializedError);
//         }
//       }
//     };
  
//     const handleClick = (userId) => {
//       if (activeUsers === userId) {
//         setActiveUsers(null);
//       } else {
//         setActiveUsers(userId);
//       }
//     };
  
//     // useEffect(() => {
//     //     try {
//     //       let payload = {
//     //         sender_id: sender_id,
//     //         receiver_id: receiver_id,
//     //       };
//     //       setLoader(true)
//     //       dispatch(getMessagesEmit(payload));
//     //     } catch (error) {
//     //       console.log(error);
//     //       setLoader(false);
//     //     }
    
//     //     socket?.on("response", (data) => {
//     //       if (data?.object_type == "get_messages") {
//     //         if (data?.data) {
//     //           setMessage(data?.data);
//     //           setLoader(false)
//     //         }
//     //       } else if (data?.object_type == "unavailable") {
//     //         toast.error("This user is not available for conversation");
//     //         setCurrentMessage("");
//     //         setShowPicker(false);
//     //       } else if (data?.object_type == "get_message") {
//     //         setMessage((arr) => [...arr, data?.data]);
//     //         setCurrentMessage("");
//     //         setShowPicker(false);
//     //       }
//     //     });
//     //   }, [socket]);
//       const sendMessage = async (e) => {
//         e.preventDefault();
//         if (currentMessage == "") {
//           toast.error("message cannot be empty");
//         } else {
//           try {
//             let payload = {
//               sender_id: sender_id,
//               receiver_id: receiver_id,
//               message: currentMessage,
//             };
//             // dispatch(sendMessageEmit(payload));
//           } catch (error) {
//             console.log(error);
//           }
//         }
//       };
    
//       useEffect(() => {
//         lastMessageRef.current?.scrollHeight && (lastMessageRef.current.scrollTop = lastMessageRef.current.scrollHeight);
//       }, [message]);


//     return (
//         <>
//             <div className="main-panel">
//                 <div className="content-wrapper2">
//                     <div className="page-header">
//                         <h3 className="page-title">
//                             <span className="page-title-icon bg-gradient-info text-white me-2">
//                                 <i className="mdi mdi-home" />
//                             </span> Chat
//                         </h3>
//                     </div>
//                     <div className="row mt-5">
//                         <div className="col-lg-7 col-md-7 col-12">
//                             <div className="single_user_msgs">
//                                 <div className="singleUser_title d_flexSpacebetween">
//                                     <h3 className="user_title">{userId?.basics?.name ? userId?.basics?.name : "N/A"}</h3>
//                                     <div className="d_flexStart msgattachment fileattachemnt">
//                                         <a href="javascript:void(0)" className="viewmore menu-dots" onClick={() => handleClick(userId)}>
//                                             <img src="assets/images/menu-dots.png" alt="" />
//                                         </a>
//                                         <div className={`viewmore_detail ${activeUsers ? "active" : ""}`}>
//                                             <ul>
//                                                 {/* <li className="account_detailList">
//                         <Link to={`/profile`} state={{ userId: userId }}>
//                           <img src="assets/images/user_icon.png" alt="" />
//                           View Profile
//                         </Link>
//                       </li> */}
                                              
//                                             </ul>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="user_msg_detail" ref={lastMessageRef}>
//                                     {loader ? <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", width: "100%", backgroundColor: "transparent", zIndex: 1500, }}>
//                                         <div className="fa-2x">
//                                             <i style={{ color: "#1D454C" }} className="fas fa-solid fa-spinner fa-spin-pulse"></i>
//                                         </div>
//                                     </div> :
//                                         message?.map((item, i) =>
//                                             item?.sender_id?._id == sender_id ? (
//                                                 <div className="msgsent">
//                                                     <small> {moment(item?.createdAt).fromNow()}</small>
//                                                     <div className="user_msg_desc_bg">
//                                                         {item?.files?.length > 0 ? (
//                                                             <div className={item?.files?.length == 1 ? "image-grid-single" : "image-grid"}>
//                                                                 {item?.files?.slice(0, 4)?.map((data, i) => (
//                                                                     <div key={i} className="images-wraper" onClick={() => openImageViewer(item?.files, i)}>
//                                                                         <img src={`${data ? process.env.REACT_APP_APIURL + data : "assets/images/placeholder.jpg"} `} alt="" />
//                                                                         {i == 3 ?
//                                                                             <div>
//                                                                                 <p className="image-text">
//                                                                                     +{item?.files?.length - 4}
//                                                                                 </p>
//                                                                             </div> : <></>}
//                                                                     </div>
//                                                                 ))}
//                                                             </div>
//                                                         ) : (
//                                                             <>
//                                                                 <p className="paragraph">{item?.message}</p>
//                                                             </>
//                                                         )}
//                                                     </div>
//                                                     <div className="userImg">
//                                                         <img src={`${item?.sender_id?.imageName ? process.env.REACT_APP_APIURL + item?.sender_id?.imageName : "assets/images/placeholder.jpg"} `} alt="" />
//                                                     </div>
//                                                 </div>
//                                             ) : (
//                                                 <div className="msgRecieved">
//                                                     <div className="userImg">
//                                                         <img src={`${item?.sender_id?.imageName ? process.env.REACT_APP_APIURL + item?.sender_id?.imageName : "assets/images/placeholder.jpg"} `} alt="" />
//                                                     </div>
//                                                     <div className="user_msg_desc_bg">
//                                                         {item?.files?.length > 0 ? (
//                                                             <div className={item?.files?.length == 1 ? "image-grid-single" : "image-grid"}>
//                                                                 {item?.files?.slice(0, 4)?.map((data, i) => (
//                                                                     <div key={i} className="images-wraper" onClick={() => openImageViewer(item?.files, i)}>
//                                                                         <img src={`${data ? process.env.REACT_APP_APIURL + data : "assets/images/placeholder.jpg"} `} alt="" />
//                                                                         {i == 3 ?
//                                                                             <div>
//                                                                                 <p className="image-text">
//                                                                                     +{item?.files?.length - 4}
//                                                                                 </p>
//                                                                             </div> : <></>}
//                                                                     </div>
//                                                                 ))}
//                                                             </div>
//                                                         ) : (
//                                                             <>
//                                                                 <p className="paragraph">{item?.message}</p>
//                                                             </>
//                                                         )}
//                                                     </div>
//                                                     <small> {moment(item?.createdAt).fromNow()}</small>
//                                                 </div>
//                                             )
//                                         )
//                                     }
//                                 </div>
//                                 <form onSubmit={sendMessage}>
//                                     <div className="chatFooter">
//                                         <div className="emojisBtn">
//                                             <a href="javascript:void(0)">
//                                                 <img src="assets/images/smile.png" alt="" onClick={() => setShowPicker((val) => !val)} />
//                                             </a>
//                                         </div>
//                                         <div className="msg_ft_file" onClick={() => setShowPicker(false)}>
//                                             <a href="javascript:void(0)" className="attachmentDot menu-dots">
//                                                 <img src="assets/images/attachment.png" alt="" />
//                                             </a>
//                                             <input type="file" accept="image/*" className="attachmentDot_input" multiple id="upload-img" onChange={(e) => handleChange(e)} />
//                                         </div>
//                                         <input type="text" placeholder="Type your message..." value={currentMessage} onFocus={(e) => setShowPicker(false)} onChange={(e) => setCurrentMessage(e.target.value)} />
//                                         <button type="submit" className="sendMsg" disabled={currentMessage?.trim()?.length > 0 ? false : true}>
//                                             <a href="javascript:void(0)">
//                                                 <img src={currentMessage?.trim()?.length > 0 ? "assets/images/paper-plane.png" : "assets/images/paper-plane-disabled.png"} alt="" />
//                                             </a>
//                                         </button>
//                                     </div>
//                                 </form>
//                             </div>
//                         </div>
//                         {/* <div className="col-lg-5 col-md-5 col-12">
//                             <div className="user_profile_friends">
//                                 <div className="profile_photo">
//                                     <img src={`${userId?.imageName ? process.env.REACT_APP_APIURL + userId?.imageName : "assets/images/placeholder.jpg"} `} />
//                                 </div>
//                                 <h3 className="user_title">{userId?.basics?.name ? userId?.basics?.name : "N/A"}</h3>
//                                 <p className="user_dess">
//                                     {userId?.basics?.dob ? `${new Date().getFullYear() - new Date(moment(userId?.basics?.dob, "DD-MM-YYYY").format("YYYY-MM-DD")).getFullYear()} years old` : "N/A"}, {userId?.basics?.gender ? userId?.basics?.gender : "N/A"}, {userId?.basics?.city ? userId?.basics?.city : "N/A"}
//                                 </p>
//                                 <p className="paragraph" style={{ textAlign: "justify" }}>
//                                     {userId?.description ? userId?.description : "N/A"}
//                                 </p>
//                                 <div className="row">
//                                     {userId?.otherImages?.length > 0 ? (
//                                         userId?.otherImages?.map((item, i) => (
//                                             <div key={i} className="col-lg-4 col-md-4 col-sm-6 col-6" onClick={() => openImageViewer(userId?.otherImages, i)}>
//                                                 <div className="friend_photos">
//                                                     <img src={`${item ? process.env.REACT_APP_APIURL + item : "assets/images/placeholder.jpg"} `} alt="" />
//                                                 </div>
//                                             </div>
//                                         ))
//                                     ) : (
//                                         <></>
//                                     )}
//                                 </div>
//                                 <div className="view_all">
//                                     <Link to={`/profile`} state={{ userId: userId }}>
//                                         {" "}
//                                         View Profile{" "}
//                                     </Link>
//                                 </div>
//                             </div>
//                         </div> */}
//                     </div>
//                 </div>

//             </div>
//             {/* </div> */}

//         </>
//     )
// }

// export default Chat