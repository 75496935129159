import { toast } from 'react-toastify';
import axios from "axios";
import { addCategory, approveDisapproveAd, approveDisapproveReport, blockunblock, getAllRetailors, getAllPosts, addTutorial, deleteRestrictedArea, deletePost, deleteHuntingTutorial, dashboard, approveProfile, createNewTutor, updateHourlyRate, getTutorialList, addRestrictedArea, deleteCategory, getAllBusiness, getRestrictedArea, getAllOrders, getAllUsers, getCategory, getMarketPlace, getReportedPosts, Pp, privacy, recentCampaigns, signinUser, Tc, TcPp, terms, updatePassword, updatePp, updatePrivacy, updateTc, updateTcpp, updateTerms, userLogout, addSubCategory, getSubCategory, deleteSubCategory, getProduct, addProduct, deleteProduct, getAllDrivers, assignDriver, updateCategory, updateSubCategory, updateProduct, updateOrder, notification, email, getbanners, addBanner, deleteBanner, addDriver, driverListing, chatList, returnPolicy, updateReturnPolicy, updateShippingPolicy, shippingPolicy } from "../slices/userSlice"
export const extraReducers = (builder) => {
    builder


        // Sign In
        .addCase(signinUser.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(signinUser.fulfilled, (state, action) => {
            state.status = 'succeeded'
            toast.success(action.payload.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            localStorage.setItem("user", JSON.stringify(action?.payload?.data));
            state.user = action?.payload?.data
            console.log(state.user)
            axios.defaults.headers.common['Authorization'] = `Bearer ${action?.payload?.data?.user_authentication}`;

            state.error = null
        })
        .addCase(signinUser.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action?.payload?.message
            toast.error(action?.payload?.data?.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })


        // Dashboard
        .addCase(dashboard.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(dashboard.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            console.log(action.payload.data)
            state.dashboard = { usersCount: action.payload.data.totalUsers, users: action.payload.data.users, retailor: action.payload.data.retailor, }
            state.lineChart = action.payload.data.products
            console.log(state.dashboard)
            state.areaChart = action.payload.data.featured
        })
        .addCase(dashboard.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
        })


        // update Password
        .addCase(updatePassword.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(updatePassword.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })
        .addCase(updatePassword.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload?.data?.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })


        // Get Orders
        .addCase(getAllOrders.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getAllOrders.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null

        })
        .addCase(getAllOrders.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
        })
        // Get Users
        .addCase(getAllUsers.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getAllUsers.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null

        })
        .addCase(getAllUsers.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
        })
        // Get Driver Listing
        .addCase(driverListing.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(driverListing.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null

        })
        .addCase(driverListing.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
        })
        // Get Active Driver
        .addCase(getAllDrivers.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getAllDrivers.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null

        })
        .addCase(getAllDrivers.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
        })

        //Add Driver
        .addCase(addDriver.pending, (state, action) => {
            state.status = 'loading'
            state.error = null
        })
        .addCase(addDriver.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })
        .addCase(addDriver.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.data.message
            toast.error(action.payload?.data?.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })

        // Get Restricted area
        .addCase(getAllRetailors.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getAllRetailors.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
        })
        .addCase(getAllRetailors.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
        })

        // Get Restricted area
        .addCase(getAllPosts.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getAllPosts.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
        })
        .addCase(getAllPosts.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
        })


        // add new driver
        .addCase(createNewTutor.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(createNewTutor.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.data.message);

        })
        .addCase(createNewTutor.rejected, (state, action) => {
            state.status = 'failed'
            if (action.payload.status == 401) {
                state.error = action.payload.status
            } else {
                state.error = action.payload.data.message
                toast.error(action.payload.data.message);
            }
        })

        // add restricted area
        .addCase(addRestrictedArea.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addRestrictedArea.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.data.message);

        })
        .addCase(addRestrictedArea.rejected, (state, action) => {
            state.status = 'failed'
            if (action.payload.status == 401) {
                state.error = action.payload.status
            } else {
                state.error = action.payload.data.message
                toast.error(action.payload.data.message);
            }
        })

        // add new driver
        .addCase(updateHourlyRate.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(updateHourlyRate.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.data.message);

        })
        .addCase(updateHourlyRate.rejected, (state, action) => {
            state.status = 'failed'
            if (action.payload.status == 401) {
                state.error = action.payload.status
            } else {
                state.error = action.payload.data.message
                toast.error(action.payload.data.message);
            }
        })

        // add restricted area
        .addCase(getTutorialList.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getTutorialList.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.data.message);

        })
        .addCase(getTutorialList.rejected, (state, action) => {
            state.status = 'failed'
            if (action.payload.status == 401) {
                state.error = action.payload.status
            } else {
                state.error = action.payload.data.message
                toast.error(action.payload.data.message);
            }
        })

        .addCase(getCategory.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getCategory.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.data.message);

        })
        .addCase(getCategory.rejected, (state, action) => {
            state.status = 'failed'
            if (action.payload.status == 401) {
                state.error = action.payload.status
            } else {
                state.error = action.payload.data.message
                toast.error(action.payload.data.message);
            }
        })
        // Block unBlock
        .addCase(addTutorial.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addTutorial.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })
        .addCase(addTutorial.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.data.message
            // console.log(action.payload , "pay")
            toast.error(action.payload?.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })
        //banner list
        .addCase(getbanners.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getbanners.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.data.message);

        })
        .addCase(getbanners.rejected, (state, action) => {
            state.status = 'failed'
            if (action.payload.status == 401) {
                state.error = action.payload.status
            } else {
                state.error = action.payload.data.message
                toast.error(action.payload.data.message);
            }
        })
        // Delete Account
        .addCase(approveProfile.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(approveProfile.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })
        .addCase(approveProfile.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload?.data?.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })

        // Block unBlock
        .addCase(blockunblock.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(blockunblock.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })
        .addCase(blockunblock.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload?.data?.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })

        // Delete Post
        .addCase(deletePost.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(deletePost.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })
        .addCase(deletePost.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })

        // Delete restricted area
        .addCase(deleteRestrictedArea.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(deleteRestrictedArea.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })
        .addCase(deleteRestrictedArea.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })

        // Delete hunting tutorial
        .addCase(deleteHuntingTutorial.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(deleteHuntingTutorial.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })
        .addCase(deleteHuntingTutorial.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })

        //chat list

        .addCase(chatList.pending, (state, action) => {
            state.status = 'loading'
            state.error = null
        })
        .addCase(chatList.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
        })
        .addCase(chatList.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            if (action.payload.status === 401) {
                state.user = null
                state.userProfile = null
                state.token = null
                state.forgot = null
                state.error = action.payload.status
            } else {
                state.error = action.payload.data
            }
            console.log("after logout", action.payload.status)
        })

        // Log Out
        .addCase(userLogout.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(userLogout.fulfilled, (state, action) => {
            toast.success(action.payload.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            localStorage.clear();
            state.status = 'succeeded'
            state.user = null
            state.error = null
            state.TcPp = null
            state.categories = null
            state.dashboard = null
            state.lineChart = null
            state.areaChart = null
            state.charges = null
            state.campaigns = null
        })
        .addCase(userLogout.rejected, (state, action) => {
            toast.error(action.payload?.data?.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            state.status = 'failed'
            state.error = action.payload.message
        })

        .addCase(returnPolicy.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(returnPolicy.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            state.returnPolicy = action.payload
        })
        .addCase(returnPolicy.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
        })

        // Get TcPp
        .addCase(terms.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(terms.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            state.terms = action.payload
        })
        .addCase(terms.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
        })
        // Get TcPp
        .addCase(privacy.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(privacy.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            state.privacy = action.payload
        })
        .addCase(privacy.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
        })

        .addCase(shippingPolicy.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(shippingPolicy.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            state.shippingPolicy = action.payload
        })
        .addCase(shippingPolicy.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
        })

        .addCase(updateReturnPolicy.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(updateReturnPolicy.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            state.returnPolicy = action.payload
            toast.success(action.payload.message)
        })
        .addCase(updateReturnPolicy.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
        })

        .addCase(updateShippingPolicy.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(updateShippingPolicy.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            state.returnPolicy = action.payload
            toast.success(action.payload.message)
        })
        .addCase(updateShippingPolicy.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
        })
        // Update TcPp
        .addCase(updateTerms.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(updateTerms.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            state.privacy = action.payload
            toast.success(action.payload.message)
        })
        .addCase(updateTerms.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
        })
        // Update TcPp
        .addCase(updatePrivacy.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(updatePrivacy.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            state.terms = action.payload
            toast.success(action.payload.message)
        })
        .addCase(updatePrivacy.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
        })

        // Assign Driver
        .addCase(assignDriver.pending, (state, action) => {
            state.status = 'loading'
            state.error = null
        })
        .addCase(assignDriver.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })
        .addCase(assignDriver.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload?.data?.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })

        // Add Category
        .addCase(addCategory.pending, (state, action) => {
            state.status = 'loading'
            state.error = null
        })
        .addCase(addCategory.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })
        .addCase(addCategory.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload?.data?.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })
        // Update Category
        .addCase(updateCategory.pending, (state, action) => {
            state.status = 'loading'
            state.error = null
        })
        .addCase(updateCategory.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })
        .addCase(updateCategory.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            // console.log()
            toast.error(action.payload?.data?.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })

        //Add subcategory
        .addCase(addSubCategory.pending, (state, action) => {
            state.status = 'loading'
            state.error = null
        })
        .addCase(addSubCategory.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })
        .addCase(addSubCategory.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.data.message
            toast.error(action.payload?.data?.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })

        //Update subcategory
        .addCase(updateSubCategory.pending, (state, action) => {
            state.status = 'loading'
            state.error = null
        })
        .addCase(updateSubCategory.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })
        .addCase(updateSubCategory.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.data.message
            toast.error(action.payload?.data?.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })

        //Add Product
        .addCase(addProduct.pending, (state, action) => {
            state.status = 'loading'
            state.error = null
        })
        .addCase(addProduct.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })
        .addCase(addProduct.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.data.message
            toast.error(action.payload?.data?.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })

        //Update Product
        .addCase(updateProduct.pending, (state, action) => {
            state.status = 'loading'
            state.error = null
        })
        .addCase(updateProduct.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })
        .addCase(updateProduct.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.data.message
            toast.error(action.payload?.data?.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })
        //Update Order
        .addCase(updateOrder.pending, (state, action) => {
            state.status = 'loading'
            state.error = null
        })
        .addCase(updateOrder.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })
        .addCase(updateOrder.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.data.message
            toast.error(action.payload?.data?.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })

        // Get Sub Category
        .addCase(getSubCategory.pending, (state, action) => {
            state.status = 'loading'
            state.error = null
            state.categories = null
        })
        .addCase(getSubCategory.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            state.categories = action.payload.categories
        })
        .addCase(getSubCategory.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            state.categories = null

        })

        //Get Product
        .addCase(getProduct.pending, (state, action) => {
            state.status = 'loading'
            state.error = null
            state.categories = null
        })
        .addCase(getProduct.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            state.categories = action.payload.categories
        })
        .addCase(getProduct.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            state.categories = null

        })

        // Delete 
        .addCase(deleteCategory.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(deleteCategory.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })
        .addCase(deleteCategory.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload?.data?.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })

        .addCase(deleteSubCategory.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(deleteSubCategory.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })
        .addCase(deleteSubCategory.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload?.data?.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })

        .addCase(deleteProduct.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(deleteProduct.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })
        .addCase(deleteProduct.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload?.data?.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })

        //Add Banner
        .addCase(addBanner.pending, (state, action) => {
            state.status = 'loading'
            state.error = null
        })
        .addCase(addBanner.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })
        .addCase(addBanner.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.data.message
            toast.error(action.payload?.data?.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })

        //Delte Banner
        .addCase(deleteBanner.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(deleteBanner.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })
        .addCase(deleteBanner.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload?.data?.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })

        // Notification
        .addCase(notification.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(notification.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            state.terms = action.payload
            toast.success(action.payload.message)
        })
        .addCase(notification.rejected, (state, action) => {
            toast.error(action.payload.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            state.status = 'failed'
            state.error = action.payload.message
        })
        // Email
        .addCase(email.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(email.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            state.terms = action.payload
            toast.success(action.payload.message)
        })
        .addCase(email.rejected, (state, action) => {
            toast.error(action.payload.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            state.status = 'failed'
            state.error = action.payload.message
        })
    // Delete Category
    // .addCase(deleteCategory.pending, (state, action) => {
    //     state.status = 'loading'
    //     state.error = null

    // })
    // .addCase(deleteCategory.fulfilled, (state, action) => {
    //     state.status = 'succeeded'
    //     state.error = null
    //     toast.success(action.payload.message, {
    //         position: toast.POSITION.TOP_RIGHT
    //     });

    // })
    // .addCase(deleteCategory.rejected, (state, action) => {
    //     state.status = 'failed'
    //     state.error = action.payload.message
    //     state.categories = null

    // })

}