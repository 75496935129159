import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getSocket, getUser } from "../store/slices/userSlice";
import { toast } from "react-toastify";
const Chatbox = ({ sender_id, receiver_id, product_id, user, chatList }) => {
    const [message, setMessage] = useState([]);
    const [currentMessage, setCurrentMessage] = useState("");
    const userData = useSelector(getUser);
    const socket = useSelector(getSocket);
    const location = useLocation();
    const lastMessageRef = useRef(null);




    useEffect(() => {
        console.log(getUser)
        socket?.emit("get_messages", {
            sender_id: sender_id ? sender_id : userData?._id,
            receiver_id: receiver_id ? receiver_id : userData?._id,
            //   product_id: product_id ? product_id : location?.state?._id,
        });
        socket?.on("response", (data) => {
            if (data?.object_type == "get_messages") {
                setMessage(data?.data);
            } else if (data?.object_type == "get_message") {
                if (!message.find((arr) => arr._id === data?.data?._id)) {
                    setMessage((arr) => [data?.data, ...arr]);
                    setCurrentMessage("");
                    chatList();
                } else {
                    setMessage(message);
                }
            }
        });
        return () => socket?.off("response");
    }, [socket, sender_id, receiver_id, product_id]);



    useEffect(() => {
        lastMessageRef.current.scrollTop = lastMessageRef?.current?.scrollHeight;
    }, [message]);
    const sendMessage = (e) => {
        e.preventDefault();


        if (currentMessage == "") {
            toast.error("Message cannot be empty")
        } else {

            socket?.emit("send_message", {
                sender_id: userData?._id,
                receiver_id: receiver_id != userData?._id ? receiver_id : sender_id,
                // product_id: product_id ? product_id : location?.state?._id,
                message: currentMessage,
            });
        }

    };
    return (
        <>


            <div
                className="messaging__content"
                data-background-color="rgba(0,0,0,.05)"
                ref={lastMessageRef}
            >
                <div className="messaging__main-chat" >
                    {message
                        ?.slice(0)
                        .reverse()
                        .map((item, i) => (
                            <div
                                className={
                                    item?.sender_id?._id == user?._id
                                        ? "messaging__main-chat__bubble user"
                                        : "messaging__main-chat__bubble"
                                }
                            >
                                <p>
                                    {item?.message}
                                    <small>
                                        {moment(item?.createdAt).startOf("hour").fromNow()}
                                    </small>
                                </p>
                            </div>
                        ))}
                </div>
            </div>
            <div className="messaging__footer">
                <form className="form">
                    <div className="input-group">
                        <input
                            type="text"
                            className="form-control mr-4"
                            placeholder="Your Message"
                            value={currentMessage}
                            onChange={(e) => setCurrentMessage(e.target.value)}
                        />
                        <div className="input-group-append">
                            <button className="excel-btn col-reds w-10 pt-1 pb-1 ml-auto" onClick={(e) => sendMessage(e)}>
                                Send
                            </button>
                            {/* <button
                  onClick={(e) => sendMessage(e)}
                  className="btn btn-primary"
                  type="submit"
                >
                  Send <i className="fa fa-send ml-3" />
                </button> */}
                        </div>
                    </div>
                </form>
            </div>

        </>
    );
};

export default Chatbox;
