import React, { useContext } from 'react'
import Nav from './Nav'
import Sidebar from './Sidebar'
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from '../../pages/Login';
import { useSelector } from 'react-redux'
import { getUserStatus, getUsertoken } from "../../store/slices/userSlice"
import Dashboard from '../../pages/Dashboard';
import Userlist from '../../pages/UserList';
import TermsAndConditions from '../../pages/TermsAndConditions';
import PrivacyPolicy from '../../pages/PrivacyPolicy';
import Spinner from '../Spinner';
import { context } from '../../context/context';
import RetailorList from '../../pages/RetailorList';
import CategoryList from '../../pages/CategoryList';
import SubCategoryList from '../../pages/SubCategoryList';
import ProductList from '../../pages/ProductList';
import OrderList from '../../pages/OrderList';
import Notification from '../../pages/Notification';
import Email from '../../pages/Email';
import BannerList from '../../pages/BannerList';
import DriverList from '../../pages/DriverList';
import Chat from '../../pages/Chat';
import ReturnPolicy from '../../pages/ReturnPolicy';
import DeliveryAndShipping from '../../pages/DeliveryAndShipping';


const Layout = () => {
    const { isLoading } = useContext(context);
    const status = useSelector(getUserStatus)
    const authtoken = useSelector(getUsertoken)
    return (
        <>
            {status == 'loading' || isLoading ? <Spinner /> : <></>}
            <div className={!authtoken ? "" : "wrapper"}>
                <BrowserRouter>
                    {authtoken ? <>
                        <Sidebar />
                        <div style={{ width: "100%" }}>
                            <Nav />
                            <Routes>
                                <Route path="*" element={<Navigate to="/admin/dashboard" />} />
                                <Route path="/admin/dashboard" exact element={<Dashboard />} />
                                <Route path="/admin/user-list" exact element={<Userlist />} />
                                <Route path="/admin/trader-list" exact element={<RetailorList />} />
                                <Route path="/admin/driver-list" exact element={<DriverList />} />
                                <Route path="/admin/terms-and-conditions" exact element={<TermsAndConditions />} />
                                <Route path="/admin/privacy-policy" exact element={<PrivacyPolicy />} />
                                <Route path="/admin/return-policy" exact element={<ReturnPolicy />} />
                                <Route path="/admin/delivery-and-shipping" exact element={<DeliveryAndShipping />} />
                                <Route path="/admin/category-list" exact element={<CategoryList />} />
                                <Route path="/admin/sub-category-list" exact element={<SubCategoryList />} />
                                <Route path="/admin/product-list" exact element={<ProductList />} />
                                <Route path="/admin/order-list" exact element={<OrderList />} />
                                <Route path="/admin/notification" exact element={<Notification />} />
                                <Route path="/admin/email" exact element={<Email />} />
                                <Route path="/admin/banner-list" exact element={<BannerList />} />
                                <Route path="/admin/chat" exact element={<Chat />} />
                            </Routes>
                        </div>
                    </> :
                        <>
                            <Routes>
                                <Route path="*" element={<Navigate to="/admin/" />} />
                                <Route path="/admin/" exact element={<Login />} />
                            </Routes>
                        </>
                    }
                </BrowserRouter>
            </div>
        </>
    )
}

export default Layout