import React, { useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
// import logo from '../../assets/images/logo.png'
import { context } from '../../context/context';
const Sidebar = () => {
    var location = useLocation()
    const { toggleButton } = useContext(context);
    return (
        <>
            <nav id="sidebar" className={toggleButton ? 'active' : ""} >
                <div className="sidebar-header">
                    <div className="logo text-center shadowfilter">
                        <Link to="/admin/dashboard"><img src="/assets/images/logo.png" style={{ width: "70%" }} alt="logo" className="img-fluid" /></Link>
                    </div>
                </div>
                <ul className="list-unstyled components">
                    <li className={location?.pathname === "/admin/dashboard" ? "active" : "noactive"}> <Link to="/dashboard">Dashboard</Link> </li>
                    <li className={location?.pathname === "/admin/student-list" || location?.pathname === "/admin/tutor-list" ? "active" : "noactive"}>
                        <a href="#user" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">Users Management</a>
                        <ul className={location?.pathname === "/admin/user-list" || location?.pathname === "/admin/trader-list" ||  location?.pathname === "/admin/driver-list" ? "list-unstyled" : "list-unstyled collapse"} id="user">
                            <li className={location?.pathname === "/admin/user-list" ? "active" : "noactive"}> <Link to="/admin/user-list">Public User List</Link> </li>
                            <li className={location?.pathname === "/admin/trader-list" ? "active" : "noactive"}> <Link to="/admin/trader-list">Trade User List</Link> </li>
                            <li className={location?.pathname === "/admin/driver-list" ? "active" : "noactive"}> <Link to="/admin/driver-list">Driver List</Link> </li>
                        </ul>
                    </li>
                    <li className={location?.pathname === "/admin/category-list" || location?.pathname === "/admin/sub-category-list" || location?.pathname === "/admin/product-list" ? "active" : "noactive"}>
                        <a href="#category" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">Product Management</a>
                        <ul className={location?.pathname === "/admin/category-list" || location?.pathname === "/admin/sub-category-list" || location?.pathname === "/admin/product-list" ? "list-unstyled" : "list-unstyled collapse"} id="category">
                            <li className={location?.pathname === "/admin/category-list" ? "active" : "noactive"}> <Link to="/admin/category-list">Category List</Link> </li>
                            <li className={location?.pathname === "/admin/sub-category-list" ? "active" : "noactive"}> <Link to="/admin/sub-category-list">Sub Category List</Link> </li>
                            <li className={location?.pathname === "/admin/product-list" ? "active" : "noactive"}> <Link to="/admin/product-list">Product List</Link> </li>

                        </ul>

                    </li>
                    <li className={location?.pathname === "/admin/order-list" ? "active" : "noactive"}>
                        <a href="#order" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">Order Management</a>
                        <ul className={location?.pathname === "/admin/order-list" ? "list-unstyled" : "list-unstyled collapse"} id="order">
                            <li className={location?.pathname === "/admin/order-list" ? "active" : "noactive"}> <Link to="/admin/order-list">Order List</Link> </li>
                        </ul>
                    </li>
                    <li className={location?.pathname === "/admin/banner-list" || location?.pathname === "/admin/notification" || location?.pathname === "/admin/email" ? "active" : "noactive"}>
                        <a href="#promotions" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">Promotions</a>
                        <ul className={location?.pathname === "/admin/banner-list" || location?.pathname === "/admin/notification" || location?.pathname === "/admin/email" ? "list-unstyled" : "list-unstyled collapse"} id="promotions">
                            <li className={location?.pathname === "/admin/banner-list" ? "active" : "noactive"}> <Link to="/admin/banner-list">Promotional Banner</Link> </li>
                            <li className={location?.pathname === "/admin/notification" ? "active" : "noactive"}> <Link to="/admin/notification">Notification</Link> </li>
                            <li className={location?.pathname === "/admin/email" ? "active" : "noactive"}> <Link to="/admin/email">Email</Link> </li>
                        </ul>
                    </li>
                    {/* <li className={location?.pathname === "/admin/notification" ? "active" : "noactive"}> <Link to="/admin/notification">Notification</Link> </li>
                    <li className={location?.pathname === "/admin/email" ? "active" : "noactive"}> <Link to="/admin/email">Email</Link> </li> */}
                    <li className={location?.pathname === "/admin/terms-and-conditions" || location?.pathname === "/admin/privacy-policy" || location?.pathname === "/admin/return-policy" || location?.pathname === "/admin/delivery-and-shipping" ? "active" : "noactive"}>
                        <a href="#content" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">Content Management</a>
                        <ul className={location?.pathname === "/admin/terms-and-conditions" || location?.pathname === "/admin/privacy-policy"  || location?.pathname === "/admin/return-policy"  || location?.pathname === "/admin/delivery-and-shipping"? "list-unstyled" : "collapse list-unstyled"} id="content">
                            <li className={location?.pathname === "/admin/terms-and-conditions" ? "active" : "noactive"}> <Link to="/admin/terms-and-conditions">Terms & Conditions</Link> </li>
                            <li className={location?.pathname === "/admin/privacy-policy" ? "active" : "noactive"}> <Link to="/admin/privacy-policy">Privacy Policy</Link> </li>
                            <li className={location?.pathname === "/admin/return-policy" ? "active" : "noactive"}> <Link to="/admin/return-policy">Return Policy</Link> </li>
                            <li className={location?.pathname === "/admin/delivery-and-shipping" ? "active" : "noactive"}> <Link to="/admin/delivery-and-shipping">Delivery And shipping</Link> </li>
                        </ul>
                    </li>
                    <li className={location?.pathname === "/admin/chat" ? "active" : "noactive"}> <Link to="/admin/chat">Chat</Link> </li>
                </ul>
            </nav>
        </>

    )
}

export default Sidebar