import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { addCategory, deleteCategory, updateCategory, getCategory, getUserStatus } from '../store/slices/userSlice';
import { CSVLink } from "react-csv";
import $ from "jquery"
import 'datatables.net'
import Modal from 'react-modal';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        width: "30%",
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
Modal.setAppElement('#root');
const CategoryList = () => {
    const [id, setId] = useState()
    const dispatch = useDispatch()
    const [users, setUsers] = useState(null)
    const status = useSelector(getUserStatus)
    const [userDetail, setUserDetail] = useState(null)
    const [attachment, setAttachment] = useState();
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalType, setModalType] = useState()
    function viewModal(item, type) {
        setIsOpen(true);
        if (type == "userDetail") {
            setUserDetail(item)
        } else if (type == "delete") {
            setId(item)
        }else if (type == "edit") {
            console.log("item", item)
            setId(item?._id)
            setTitle(item?.name)
        }
        setModalType(type)
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
        setAttachment()
        setTitle(null)
        setDescription(null)
    }
    console.log('imagessssssssssssss', attachment)
    function AttachmenthandleChange(e) {
        e.preventDefault();
        console.log("e.target.files[0]", e.target.files);
        setAttachment(e.target.files[0]);
    }

    const tutorialDelete = async (id) => {
        try {
            await dispatch(deleteCategory(id)).unwrap()
            setIsOpen(false)
            $('#tableData')
                .DataTable().destroy();
            try {
                Users()
            } catch (rejectedValueOrSerializedError) {
                console.log(rejectedValueOrSerializedError)
            }
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError)
        }
    }

    const createTutorial = async () => {
        try {
console.log("working")
            // const data = new FormData();
            // data.append("title", title);
            // data.append("description", description);
            // data.append("tutorial_video", attachment);

            await dispatch(addCategory({name : title})).unwrap()
            if (users) {

                $('#tableData')
                    .DataTable().destroy();
            }
            closeModal()
            Users()
            setModalType(null)
            setTitle(null)
            setModalType(null)
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError)
        }
    }

    const categoryUpdate = async () => {
        try {
// console.log("working")
            // const data = new FormData();
            // data.append("title", title);
            // data.append("description", description);
            // data.append("tutorial_video", attachment);

            await dispatch(updateCategory({name : title , category_id : id})).unwrap()
            if (users) {

                $('#tableData')
                    .DataTable().destroy();
            }
            closeModal()
            Users()
            setModalType(null)
            setTitle(null)
            setModalType(null)
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError)
        }
    }


const Users = async () => {
    try {
        setUsers(null)
        const response = await dispatch(getCategory()).unwrap()
        console.log(response.data)
        setUsers(response?.data)
    } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError)
    }
}

useEffect(() => {
    let mount = true
    if (mount) {
        Users();
    }
    return () => {
        mount = false
    }
}, [])

useEffect(() => {
    if (users) {
        $('#tableData')
            .DataTable({
                lengthMenu: [10, 25, 50, 100, 200],
                language: {
                    "emptyTable": "Users Not Found"
                },
                destroy: true,
            });
    }
}, [users])



return (
    <>
        <Modal
            closeTimeoutMS={500}
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Change Password"
        >
            <div className='change-password-modal' id="exampleModalCenter" tabIndex={-1} aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{ display: "block", zIndex: 100 }}>
                {modalType == "delete" ? <>
                    <p className="pass-text">Are you sure you want to delete category? </p>
                    <button onClick={closeModal} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                    <div className="modal-body">
                        <form >
                            <div className="pass-form-wrap" style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                                <div className="login-button mt-2" style={{ width: "40%" }}>
                                    <button type="button" onClick={() => tutorialDelete(id)} className="cta-btn col-reds w-100">Delete</button>
                                </div>
                                <div className="login-button mt-2" style={{ width: "40%" }} >
                                    <button type="button" onClick={closeModal} className="cta-btn col-reds w-100">Cancel</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </> : modalType == "create" ? <>
                    <p className="pass-text">Add Category</p>
                    <button onClick={closeModal} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                    <div className="modal-body">
                        <div className="input-group input-group-sm mb-3 w-100">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroup-sizing-sm">Title</span>
                            </div>
                            <input type="text" className="form-control" aria-label="Name" value={title} onChange={(e) => setTitle(e.target.value)} />
                        </div>
                        <button className="excel-btn col-reds w-10 pt-1 pb-1" type="submit" onClick={createTutorial}>Add</button>
                    </div>
                </> : modalType == "edit" ? <>
                    <p className="pass-text">Update Category</p>
                    <button onClick={closeModal} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                    <div className="modal-body">
                        <div className="input-group input-group-sm mb-3 w-100">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroup-sizing-sm">Title</span>
                            </div>
                            <input type="text" className="form-control" aria-label="Name" value={title} onChange={(e) => setTitle(e.target.value)} />
                        </div>
                        <button className="excel-btn col-reds w-10 pt-1 pb-1" type="submit" onClick={categoryUpdate}>Update</button>
                    </div>
                </> : <></>}
            </div>
        </Modal>
        <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginTop: users ? "3%" : "12%"
        }}>
            <section className="excel-sec">
                <div className="container tableContainer">
                    <div className="  mt-2 mb-3" >
                        <button className="excel-btn col-reds w-10 pt-1 pb-1" onClick={() => viewModal(null, "create")}>
                            Add Category
                        </button>

                    </div>
                </div>
            </section>
            <section className="coupon-sec-2">
                <div className="container tableContainer">
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="card shadow mb-4">
                                <div className="card-body">
                                    <div className="table-responsive" id="tableready">
                                        <table id="tableData" className="table table-bordered" style={{ width: '100%', textAlign: "center" }}>
                                            <thead>
                                                {users ? (<tr>
                                                    <th>S.No</th>
                                                    <th>Title</th>
                                                    {/* <th>Description</th> */}
                                                    <th>Creation date</th>
                                                    {/* <th>Detail</th> */}
                                                    <th>Action</th>
                                                </tr>) : (<tr></tr>)}
                                            </thead>
                                            <tbody >
                                                {users?.map((item, i) => (
                                                    <tr key={i}>
                                                        <td>{i + 1}</td>
                                                        <td>{item?.name ? item?.name : <>N/A</>}</td>
                                                        {/* <td style={{
                                                            wordWrap: "break-word",
                                                            wordBreak: "break-all",
                                                            whiteSpace: "normal",
                                                        }}>{item?.description ? item?.description : <>N/A</>}</td> */}
                                                        <td>{moment(item?.createdAt ? item?.createdAt : <>N/A</>).format("DD-MMM-YYYY")}</td>
                                                        {/* <td>
                                                                <span className="edit-icon" >
                                                                    <span style={{ cursor: "pointer", fontWeight: "bold", margin: 10, fontSize: 13, }} onClick={() => viewModal(item, "userDetail")}  ><i className="fas fa-eye"></i> View</span>
                                                                </span>
                                                            </td> */}
                                                        <td>
                                                            <span className="edit-icon" >
                                                                <span style={{ cursor: "pointer", fontWeight: "bold", margin: 10 }} onClick={() => viewModal(item, "edit")}  ><i className="fas fa-edit"></i></span>
                                                                <span style={{ cursor: "pointer", fontWeight: "bold", margin: 10 }} onClick={() => viewModal(item?._id, "delete")}  ><i className="fas fa-trash-alt"></i></span>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                )
                                                )}
                                            </tbody>
                                            <tfoot>
                                                {users ? (<tr>
                                                    <th>S.No</th>
                                                    <th>Title</th>
                                                    {/* <th>Description</th> */}
                                                    <th>Creation date</th>
                                                    {/* <th>Detail</th> */}
                                                    <th>Action</th>
                                                </tr>) : (<tr><th>{status == "loading" ? "Loading..." : "No Category Found"}</th></tr>)}
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div >
    </>
)
}
export default CategoryList
