import React, { useEffect,useMemo,useRef,useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { notification } from '../store/slices/userSlice'
const Notification = () => {
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [role, setRole] = useState()
  const dispatch = useDispatch()

  const sendNotification = async () => {
    try {
       await dispatch(notification({title: title , body : description , role: role})).unwrap()
      try {
        setDescription("");
        setTitle("");
        setRole("");
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError)
      }
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError)
    }
  }
//   useEffect(() => {
//     async function TcPpData() {
//       try {
//         await dispatch(terms()).unwrap()
        
//       } catch (rejectedValueOrSerializedError) {
//         console.log(rejectedValueOrSerializedError)
//       }
//     }
//     console.log("sdscxzcxzc",Tc)
    
    
//       TcPpData();
    
   
//   }, [])

  return (
    <>
    <div className="term-condition-sec">
      <div className="container type-2">
        <div className="term-condition-wrap">
          <div className="term-condition-box">
            <h1 className="heading-2">Notification</h1>
            {/* <div className="content-box"> */}

                <select className="wrap-input100 validate-input mt-2"
                  id="myDropdown"
                  style={{ backgroundColor: 'transparent'  }}
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                >
                  <option value="">Select role</option>
                  <option value="user">User</option>
                  <option value="retailer">Retailer</option>
                  <option value="both">Both</option>
                </select>

              <div className="wrap-input100 validate-input">
                <input
                  className="input100"
                  type="text"
                  placeholder="Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>

              <div className="wrap-input100 validate-input">
                <textarea
                  className="wrap-input100 validate-input mt-2"
                  rows="10"
                  cols="50"
                  style={{ backgroundColor: 'transparent'   }}
                  value={description}
                  placeholder="Message"
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            <div className="login-button mt-2">
              <button onClick={sendNotification} className="cta-btn col-reds w-20 pt-1 pb-1">
                Send
              </button>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>

    </>
  )
}

export default Notification