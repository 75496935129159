import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import JoditEditor from "jodit-react";
import { email } from '../store/slices/userSlice'
const Email = () => {
    const [title, setTitle] = useState("")
    const [useremail, setUseremail] = useState("")
    const [description, setDescription] = useState("")
    const [role, setRole] = useState()
    const dispatch = useDispatch()
    const editor = useRef(null);

    const config = useMemo(
        () => ({
            askBeforePasteHTML: false,
            askBeforePasteFromWord: false,
            placeholder: "Start typing...",
        }),
        []
    );

    const sendEmail = async () => {
        try {
            await dispatch(email({ subject: title, body: description, role: role, email: useremail })).unwrap()
            try {
                setDescription("");
                setTitle("");
                setRole("");
                setUseremail("");
            } catch (rejectedValueOrSerializedError) {
                console.log(rejectedValueOrSerializedError)
            }
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError)
        }
    }
    //   useEffect(() => {
    //     async function TcPpData() {
    //       try {
    //         await dispatch(terms()).unwrap()

    //       } catch (rejectedValueOrSerializedError) {
    //         console.log(rejectedValueOrSerializedError)
    //       }
    //     }
    //     console.log("sdscxzcxzc",Tc)


    //       TcPpData();


    //   }, [])

    return (
        <>
            <div className="term-condition-sec">
                <div className="container type-2">
                    <div className="term-condition-wrap">
                        <div className="term-condition-box">
                            <h1 className="heading-2">Email</h1>
                            {/* <div className="content-box"> */}

                            <select className="wrap-input100 validate-input mt-2"
                                id="myDropdown"
                                style={{ backgroundColor: 'transparent' }}
                                value={role}
                                onChange={(e) => setRole(e.target.value)}
                            >
                                <option value="">Select role</option>
                                <option value="user">User</option>
                                <option value="retailer">Retailer</option>
                                <option value="both">Both</option>
                                <option value="individual">Individual</option>
                            </select>
                            {role === 'individual' ? <>
                                <div className="wrap-input100 validate-input">
                                    <input
                                        className="input100"
                                        type="email"
                                        placeholder="Email"
                                        value={useremail}
                                        onChange={(e) => setUseremail(e.target.value)}
                                    />
                                </div>
                            </>
                                : <></>
                            }
                            <div className="wrap-input100 validate-input">
                                <input
                                    className="input100"
                                    type="text"
                                    placeholder="Subject"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </div>

                            <div className="wrap-input100 validate-input">
                                <JoditEditor
                                    ref={editor}
                                    value={description}
                                    config={config}
                                    tabIndex={1} // tabIndex of textarea
                                    onBlur={(newContent) => setDescription(newContent)} // preferred to use only this option to update the content for performance reasons
                                    onChange={(newContent) => { }}
                                />
                            </div>
                            <div className="login-button mt-2">
                                <button onClick={sendEmail} className="cta-btn col-reds w-20 pt-1 pb-1">
                                    Send
                                </button>
                            </div>
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Email