import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { addBanner, deleteBanner, getbanners, getBannerStatus } from '../store/slices/userSlice';
import Modal from 'react-modal';
import $ from "jquery"
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        width: "30%",
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
Modal.setAppElement('#root');
const BannerList = () => {
    const [id, setId] = useState()
    const dispatch = useDispatch()
    const [banners, setBanners] = useState(null)
    const [bannerDetail, setbannerDetail] = useState(null)
    const status = useSelector(getBannerStatus)
    const [type, setType] = useState("");
    const [productImage, setProductImage] = useState([]);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalType, setModalType] = useState()
    function viewModal(item, type) {
        setIsOpen(true);
        if (type == "bannerDetail") {
            setbannerDetail(item)
        } else if (type == "delete") {
            setId(item)
        }
        setModalType(type)
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const handleImageUpload = (event) => {
        // console.log('event.target :>> ', event.target.files);
        const selectedImages = event.target.files[0];
        setProductImage(productImage.concat(selectedImages));
    };

    const handleImageRemove = (e, index) => {
        e.preventDefault();
        console.log("workingggg");
        const updatedImages = [...productImage];
        updatedImages.splice(index, 1);

        setProductImage(updatedImages);
    };

    const bannerDelete = async (id) => {
        try {
            await dispatch(deleteBanner(id)).unwrap()
            setIsOpen(false)
            $('#tableData')
                .DataTable().destroy();
            try {
                Banners()
            } catch (rejectedValueOrSerializedError) {
                console.log(rejectedValueOrSerializedError)
            }
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError)
        }
    }

    const createBanner = async () => {
        try {
            console.log("working")
            const data = new FormData();
            data.append("banner_type", type);
            data.append("banner", productImage[0]);
            await dispatch(addBanner(data)).unwrap()
            if (banners) {

                $('#tableData')
                    .DataTable().destroy();
            }
            closeModal()
            Banners()
            setModalType(null)
            setType()
            setProductImage([])
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError)
        }
    }

    const Banners = async () => {
        try {
            setBanners(null)
            const response = await dispatch(getbanners()).unwrap()
            console.log(response.data)
            setBanners(response?.data)
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError)
        }
    }

    useEffect(() => {
        let mount = true
        if (mount) {
            Banners();
        }
        return () => {
            mount = false
        }
    }, [])

    useEffect(() => {
        if (banners) {
            $('#tableData')
                .DataTable({
                    lengthMenu: [10, 25, 50, 100, 200],
                    language: {
                        "emptyTable": "Banners Not Found"
                    },
                    destroy: true,
                });
        }
    }, [banners])



    return (
        <>
            <Modal
                closeTimeoutMS={500}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Change Password"
            >
                <div className='change-password-modal' id="exampleModalCenter" tabIndex={-1} aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{ display: "block", zIndex: 100 }}>
                    {modalType == "delete" ? <>
                        <p className="pass-text">Are you sure you want to delete category? </p>
                        <button onClick={closeModal} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        <div className="modal-body">
                            <form >
                                <div className="pass-form-wrap" style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                                    <div className="login-button mt-2" style={{ width: "40%" }}>
                                        <button type="button" onClick={() => bannerDelete(id)} className="cta-btn col-reds w-100">Delete</button>
                                    </div>
                                    <div className="login-button mt-2" style={{ width: "40%" }} >
                                        <button type="button" onClick={closeModal} className="cta-btn col-reds w-100">Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </> : modalType == "create" ? <>
                        <p className="pass-text">Add Banner</p>
                        <button onClick={closeModal} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        <div className="modal-body">
                            <div>
                                <div className="input-group input-group-sm mb-3 w-100">
                                    <input
                                        type="file"
                                        className="form-control uploader"
                                        placeholder='Upload image'
                                        name="attachment[]"
                                        accept="image/png, image/jpeg"
                                        id="upload-img"
                                        onChange={handleImageUpload}
                                    />
                                </div>
                                {productImage.length > 0 && (
                                    <section>
                                        <div className="row" style={{ display: "inline" }}>
                                            <div
                                                className="col-lg-4 col-md-4 col-sm-6 col-12"
                                                style={{ display: "inline" }}
                                            >
                                                <div className="userCard" style={{ display: "inline" }}>
                                                    <div style={{ display: "inline" }}>
                                                        {productImage?.map((image, index) => (
                                                            <>
                                                                <div
                                                                    style={{
                                                                        display: "inline",
                                                                        position: "relative",
                                                                    }}
                                                                    key={index}
                                                                >
                                                                    <img
                                                                        src={URL.createObjectURL(image)}
                                                                        alt={`Image ${index}`}
                                                                        style={{
                                                                            width: "350px",
                                                                            height: "200px",
                                                                            borderRadius: "20px",
                                                                            marginBottom: "10px",
                                                                            marginRight: "10px",
                                                                            marginLeft: "10px",
                                                                        }}
                                                                    />
                                                                    <button
                                                                        style={{
                                                                            borderRadius: "100%",
                                                                            position: "absolute",
                                                                            right: "20px",
                                                                            marginTop: "2px",
                                                                        }}
                                                                        onClick={(e) => handleImageRemove(e, index)}
                                                                    >
                                                                        <i
                                                                            style={{
                                                                                color: "red",
                                                                                backgroundColor: "white",
                                                                            }}
                                                                            className="fas fa-trash-alt"
                                                                        ></i>
                                                                    </button>
                                                                </div>
                                                            </>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </section>
                                )}

                            </div>

                            {/* <div className="input-group input-group-sm mb-3 w-500" style={{ width: "350px" }}>
                                <div className="input-group-prepend"> */}

                            <select className="wrap-input100 validate-input mt-2"
                                id="myDropdown"
                                style={{ backgroundColor: 'transparent' }}
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                            >
                                <option value="">Select category</option>
                                <option value="best_selling">Best Selling</option>
                                <option value="new_arrival">New Arrival</option>
                                <option value="limted_stock">Limited Stock</option>
                                <option value="special_offers">Special Offers</option>
                            </select>
                            {/* </div> */}
                            {/* </div> */}
                            <button className="excel-btn col-reds w-10 pt-1 pb-1" type="submit" onClick={createBanner}>Add</button>
                        </div>
                    </> : modalType == "bannerDetail" ? <>
                        <p className="pass-text">Banner Image</p>
                        <button onClick={closeModal} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        <div className="modal-body">
                            <div className="modal-body d-flex justify-content-center align-items-center">
                                <img
                                    src={`${process.env.REACT_APP_IMAGEURL}${bannerDetail?.banner_image}`}
                                    alt={`Banner Image`}
                                    style={{
                                        width: "300px",
                                        height: "200px",
                                        borderRadius: "20px",
                                        marginBottom: "10px",
                                    }}
                                />
                            </div>
                        </div>
                    </> : <></>}
                </div>
            </Modal>
            <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginTop: banners ? "3%" : "12%"
            }}>
                <section className="excel-sec">
                    <div className="container tableContainer">
                        <div className="  mt-2 mb-3" >
                            <button className="excel-btn col-reds w-10 pt-1 pb-1" onClick={() => viewModal(null, "create")}>
                                Add Banner
                            </button>

                        </div>
                    </div>
                </section>
                <section className="coupon-sec-2">
                    <div className="container tableContainer">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="card shadow mb-4">
                                    <div className="card-body">
                                        <div className="table-responsive" id="tableready">
                                            <table id="tableData" className="table table-bordered" style={{ width: '100%', textAlign: "center" }}>
                                                <thead>
                                                    {banners ? (<tr>
                                                        <th>S.No</th>
                                                        <th>Banner Category</th>
                                                        <th>Creation date</th>
                                                        <th>Action</th>
                                                    </tr>) : (<tr></tr>)}
                                                </thead>
                                                <tbody >
                                                    {banners?.map((item, i) => (
                                                        <tr key={i}>
                                                            <td>{i + 1}</td>
                                                            <td>
                                                                {item?.banner_type === 'best_selling' ? (
                                                                    <>Best Selling</>
                                                                ) : item?.banner_type === 'new_arrival' ? (
                                                                    <>New Arrival</>
                                                                ) : item?.banner_type === 'limted_stock' ? (
                                                                    <>Limited Stock</>
                                                                ) :
                                                                    item?.banner_type === 'special_offers' ? (
                                                                        <>Special Offers</>
                                                                    ) :
                                                                        (
                                                                            <>N/A</>
                                                                        )}
                                                            </td>
                                                            <td>{moment(item?.createdAt ? item?.createdAt : <>N/A</>).format("DD-MMM-YYYY")}</td>
                                                            <td>
                                                                <span className="edit-icon" >
                                                                    <span style={{ cursor: "pointer", fontWeight: "bold", margin: 10, fontSize: 13, }} onClick={() => viewModal(item, "bannerDetail")}  ><i className="fas fa-eye"></i></span>
                                                                    <span style={{ cursor: "pointer", fontWeight: "bold", margin: 10 }} onClick={() => viewModal(item?._id, "delete")}  ><i className="fas fa-trash-alt"></i></span>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    )
                                                    )}
                                                </tbody>
                                                <tfoot>
                                                    {banners ? (<tr>
                                                        <th>S.No</th>
                                                        <th>Banner Category</th>
                                                        <th>Creation date</th>
                                                        <th>Action</th>
                                                    </tr>) : (<tr><th>{status == "loading" ? "Loading..." : "No Banner Found"}</th></tr>)}
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
        </>
    )
}
export default BannerList
