import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { blockunblock, deleteAccount, getAllOrders, getUserStatus, getAllDrivers, assignDriver, updateOrder } from '../store/slices/userSlice';
import { CSVLink } from "react-csv";
import $ from "jquery"
import 'datatables.net'
import Modal from 'react-modal';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        width: "30%",
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
Modal.setAppElement('#root');
const OrderList = () => {
    const [id, setId] = useState()
    const [drivers, setDrivers] = useState(null)
    const [orderId, setOrderId] = useState()
    const [driverId, setDriverId] = useState()
    const [driverName, setDriverName] = useState()
    const [subTotal, setSubTotal] = useState("")
    const [tax, setTax] = useState("")
    const [dc, setDc] = useState("")
    const [totalPrice, setTotalPrice] = useState("")
    const [orderStatus, setOrderStatus] = useState("")
    const [orderDate, setOrderDate] = useState("")
    // const [orderDeliveryDate, setOrderDeliveryDate] = useState("")
    const [orderPickup, setOrderPickup] = useState()
    const dispatch = useDispatch()
    const [users, setUsers] = useState(null)
    const status = useSelector(getUserStatus)
    const [userDetail, setUserDetail] = useState(null)
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalType, setModalType] = useState()
    const [orderStatusList, setOrderStatusList] = useState(["Recieved", "In_Process", "On_The_Way", "Completed", "Cancelled"])
    // var csvData = [
    //     ["Name", "Email", "Phone Number", "Address", "Country", "State", "City", "Device Type"],
    // ]
    // users?.map((item) =>
    //     csvData.push([`${item?.name}`, `${item?.email}`, `${item?.phone_number}`, `${item?.address}`, `${item?.country}`, `${item?.state}`, `${item?.city}`, `${item?.user_device_type}`])
    // )

    function viewModal(item, type) {
        setIsOpen(true);
        if (type == "userDetail") {
            setUserDetail(item)
        } else if (type == "delete") {
            setId(item)
        } else if (type == "driver") {
            setOrderId(item)
        } else if (type == "edit") {
            setId(item?._id)
            setDriverId(item?.driver_id?._id)
            setDriverName(item?.driver_id)
            setTax(item?.tax)
            setDc(item?.delivery_charges)
            setTotalPrice(item?.total_price)
            setSubTotal(item?.sub_total)
            setOrderStatus(item?.order_status)
            // setOrderDeliveryDate(item?.retailer_price)
        }
        setModalType(type)
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
        setOrderDate()
        setDriverId()
        setOrderId()
        setOrderPickup()
        setId()
        setDriverId()
        setDriverName()
        setTax()
        setDc()
        setTotalPrice()
        setSubTotal()
        setOrderStatus()
    }

    const accountDelete = async (id) => {
        try {
            await dispatch(deleteAccount(id)).unwrap()
            setIsOpen(false)
            $('#tableData')
                .DataTable().destroy();
            try {
                Users()
            } catch (rejectedValueOrSerializedError) {
                console.log(rejectedValueOrSerializedError)
            }
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError)
        }
    }

    const blockUnblockAccount = async (id) => {
        try {
            await dispatch(blockunblock(id)).unwrap()
            $('#tableData')
                .DataTable().destroy();
            try {
                Users()
            } catch (rejectedValueOrSerializedError) {
                console.log(rejectedValueOrSerializedError)
            }
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError)
        }
    }

    const assignToDriver = async () => {
        try {
            console.log("working")
            // console.log(title, catId)
            // const data = new FormData();
            // data.append("title", title);
            // data.append("description", description);
            // data.append("tutorial_video", attachment);

            await dispatch(assignDriver({ order_id: orderId, driver_id: driverId, order_pickup: orderPickup, deliver_on: orderDate })).unwrap()
            if (users) {

                $('#tableData')
                    .DataTable().destroy();
            }
            closeModal()
            Users()
            setModalType(null)
            setOrderDate()
            setDriverId()
            setOrderId()
            setOrderPickup()
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError)
        }
    }

    const editOrder = async () => {
        try {
            console.log("working")
            // console.log(title, catId)
            // const data = new FormData();
            // data.append("title", title);
            // data.append("description", description);
            // data.append("tutorial_video", attachment);

            await dispatch(updateOrder({ order_id: id, driver_id: driverId, total_price: totalPrice,delivery_charges: dc, tax: tax,sub_total: subTotal,order_status: orderStatus,  })).unwrap()
            if (users) {

                $('#tableData')
                    .DataTable().destroy();
            }
            closeModal()
            Users()
            setModalType(null)
            setId()
            setDriverId()
            setDriverName()
            setTax()
            setDc()
            setTotalPrice()
            setSubTotal()
            setOrderStatus()
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError)
        }
    }


    const Users = async () => {
        try {
            setUsers(null)
            const response = await dispatch(getAllOrders()).unwrap()
            console.log(response.data)
            setUsers(response?.data)
            const driverList = await dispatch(getAllDrivers()).unwrap()
            //         console.log(driverList.data)
            setDrivers(driverList?.data)
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError)
        }
    }

    useEffect(() => {
        let mount = true
        if (mount) {
            Users();
        }
        return () => {
            mount = false
        }
    }, [])

    useEffect(() => {
        if (users) {
            $('#tableData')
                .DataTable({
                    lengthMenu: [10, 25, 50, 100, 200],
                    language: {
                        "emptyTable": "Orders Not Found"
                    },
                    destroy: true,
                });
        }
    }, [users])



    return (
        <>
            <Modal
                closeTimeoutMS={500}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Change Password"
            >
                <div className='change-password-modal' id="exampleModalCenter" tabIndex={-1} aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{ display: "block", zIndex: 100 }}>
                    {modalType == "userDetail" ? <>
                        <p className="pass-text">Order Details</p>
                        <button onClick={closeModal} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        <div className="modal-body">
                            {/* <p > <b>Image:</b> {userDetail?.user_image ? <><img height="150px" width="150px" style={{ borderRadius: 5 }} src={`${process.env.REACT_APP_APIURL}${userDetail?.user_image}`}></img></> : <>No Image Found</>}</p> */}
                            {/* <p > <b>Name:</b> {userDetail?.firstName ? userDetail?.firstName + ' ' + userDetail?.lastName : <>N/A</>}</p> */}
                            <p > <b>Order No:</b> {userDetail?.order_number ? userDetail?.order_number : <>N/A</>}</p>
                            <p > <b>Order Status:</b> {userDetail?.order_status ? userDetail?.order_status : <>N/A</>}</p>
                            <p > <b>Customer Name:</b> {userDetail?.customer_details?.firstName ? userDetail?.customer_details?.firstName + ' ' + userDetail?.customer_details?.lastName : <>N/A</>}</p>
                            <p > <b>Customer Email:</b> {userDetail?.customer_details?.email ? userDetail?.customer_details?.email : <>N/A</>}</p>
                            <p > <b>Sub Total:</b> £{userDetail?.sub_total ? userDetail?.sub_total : <>N/A</>}</p>
                            <p > <b>Tax:</b> £{userDetail?.tax ? userDetail?.tax : <>N/A</>}</p>
                            <p > <b>Delivery Charges:</b> £{userDetail?.delivery_charges ? userDetail?.delivery_charges : <>N/A</>}</p>
                            <p > <b>Total Price:</b> £{userDetail?.total_price ? userDetail?.total_price : <>N/A</>}</p>
                            <p > <b>Order Placed Date:</b> {userDetail?.order_placed_date ? moment(userDetail?.order_placed_date).format("DD-MMM-YYYY") : <>N/A</>}</p>
                            <p > <b>Order Delivered Date:</b> {userDetail?.order_delivery_date != null ? moment(userDetail?.order_delivery_date).format("DD-MMM-YYYY") : <>N/A</>}</p>
                            <p > <b>Order Pickup From:</b> {userDetail?.order_pickup != null ? userDetail?.order_pickup : <>N/A</>}</p>
                            {/* <h4 style={{paddingTop : '10px' , textDecoration :'underline'}}>Driver Deatils</h4> */}
                            <p> <h4 style={{ paddingTop: '10px', textDecoration: 'underline' }} > Driver Deatils:</h4> {userDetail?.driver_id != null ? <> <p > <b>Driver Name:</b> {userDetail?.driver_id?.firstName ? userDetail?.driver_id?.firstName + ' ' + userDetail?.driver_id?.lastName : <>N/A</>}</p>
                                <p > <b>Driver Phone No:</b> {userDetail?.driver_id?.phone_number ? userDetail?.driver_id?.phone_number : <>N/A</>}</p> </> : <>Not Assigned</>}</p>
                            {/* <p > <b>Driver Name:</b> {userDetail?.driver_id?.firstName ? userDetail?.driver_id?.firstName + ' ' + userDetail?.driver_id?.lastName : <>Not Assigned</>}</p>
                            <p > <b>Driver Phone No:</b> {userDetail?.driver_id?.phone_number ? userDetail?.driver_id?.phone_number : <>N/A</>}</p> */}
                            <h4 style={{ paddingTop: '10px', textDecoration: 'underline' }}>Products</h4>
                            {userDetail?.products.map((item, i) => (
                                <div key={i}>
                                    <p><b>Product Name:</b> {item?.title ? item.title : 'N/A'}</p>
                                    <p><b>Quantity:</b> {item?.quantity ? item.quantity : 'N/A'}</p>
                                </div>
                            ))}
                            <h4 style={{ paddingTop: '10px', textDecoration: 'underline' }}>Shipping Address</h4>
                            <p > <b>Address:</b> {userDetail?.shipping_address?.address != null ? userDetail?.shipping_address?.address : <>N/A</>}</p>
                            <p > <b>Floor:</b> {userDetail?.shipping_address?.floor != null ? userDetail?.shipping_address?.floor : <>N/A</>}</p>
                            <p > <b>Postal Code:</b> {userDetail?.shipping_address?.postal_code != null ? userDetail?.shipping_address?.postal_code : <>N/A</>}</p>
                            <p > <b>Contact Person:</b> {userDetail?.shipping_address?.contact_person_name != null ? userDetail?.shipping_address?.contact_person_name : <>N/A</>}</p>
                            <p > <b>Phone No:</b> {userDetail?.shipping_address?.phone_number != null ? userDetail?.shipping_address?.phone_number : <>N/A</>}</p>
                            <p > <b>Delivery Image:</b> {userDetail?.delivery_image ? <><img height="150px" width="150px" style={{ borderRadius: 5 }} src={`${process.env.REACT_APP_APIURL}${userDetail?.delivery_image}`}></img></> : <>No Image Found</>}</p>


                        </div>
                    </> : modalType == "edit" ? <>
                        <p className="pass-text">Edit Order</p>
                        <button onClick={closeModal} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        <div className="modal-body">


                            <div className="input-group input-group-sm mb-3 w-500" style={{ width: "350px" }}>
                                <div className="input-group-prepend">

                                    <select id="myDropdown" style={{ width: "380px" }} onChange={(e) => setDriverId(e.target.value)}>
                                        <option value={driverName?._id}>{driverName != null ? driverName?.firstName + ' ' + driverName?.lastName : <>Select Option</>}</option>
                                        {drivers.length > 0
                                            ? drivers
                                                .filter(item => item._id !== driverName?._id) // Exclude the currently selected catId
                                                .map(item => (
                                                    <option key={item._id} value={item._id}>
                                                        {item.firstName + ' ' + item.lastName}
                                                    </option>
                                                ))
                                            : <option>N/A</option>
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="input-group input-group-sm mb-3 w-100">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Sub Total</span>
                                </div>
                                <input type="text" className="form-control" aria-label="Name" value={subTotal} onChange={(e) => setSubTotal(e.target.value)} />
                            </div>
                            <div className="input-group input-group-sm mb-3 w-100">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Tax</span>
                                </div>
                                <input type="text" className="form-control" aria-label="Name" value={tax} onChange={(e) => setTax(e.target.value)} />
                            </div>
                            <div className="input-group input-group-sm mb-3 w-100">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Delivery Charges</span>
                                </div>
                                <input type="text" className="form-control" aria-label="Name" value={dc} onChange={(e) => setDc(e.target.value)} />
                            </div>
                            <div className="input-group input-group-sm mb-3 w-100">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Total Price</span>
                                </div>
                                <input type="number" className="form-control" aria-label="Name" value={totalPrice} onChange={(e) => setTotalPrice(e.target.value)} />
                            </div>
                            <div className="input-group input-group-sm mb-3 w-500" style={{ width: "350px" }}>
                                <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroup-sizing-sm">Order Status</span>
                                    <select id="myDropdown" onChange={(e) => setOrderStatus(e.target.value)}>
                                        <option value={orderStatus}>{orderStatus != null ? orderStatus : <>Select Option</>}</option>
                                        {orderStatusList.length > 0
                                            ? orderStatusList
                                                .filter(item => item !== orderStatus) // Exclude the currently selected catId
                                                .map(item => (
                                                    <option key={item} value={item}>
                                                        {item}
                                                    </option>
                                                ))
                                            : <option>N/A</option>
                                        }
                                    </select>
                                </div>
                            </div>
                            {/* <div className="input-group input-group-sm mb-3 w-100">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Retail Price</span>
                                </div>
                                <input type="number" className="form-control" aria-label="Name" value={retailPrice} onChange={(e) => setRetailPrice(e.target.value)} />
                            </div>
                            <div className="input-group input-group-sm mb-3 w-100">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Discount Price</span>
                                </div>
                                <input type="number" className="form-control" aria-label="Name" value={discountPrice} onChange={(e) => setDiscountPice(e.target.value)} />
                            </div>
                            <div className="input-group input-group-sm mb-3 w-100">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Quantity</span>
                                </div>
                                <input type="number" className="form-control" aria-label="Name" value={quantity} onChange={(e) => setQuantity(e.target.value)} />
                            </div> */}
                            <button className="excel-btn col-reds w-10 pt-1 pb-1" type="submit" onClick={editOrder}>Update</button>
                        </div>
                    </> : modalType == "delete" ? <>
                        <p className="pass-text">Delete Account Confirmation</p>
                        <button onClick={closeModal} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        <div className="modal-body">
                            <form >
                                <div className="pass-form-wrap" style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                                    <div className="login-button mt-2" style={{ width: "40%" }}>
                                        <button type="button" onClick={() => accountDelete(id)} className="cta-btn col-reds w-100">Delete</button>
                                    </div>
                                    <div className="login-button mt-2" style={{ width: "40%" }} >
                                        <button type="button" onClick={closeModal} className="cta-btn col-reds w-100">Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </> : modalType == "driver" ? <>
                        <p className="pass-text">Assign Driver</p>
                        <button onClick={closeModal} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        <div className="modal-body">
                            <div className="input-group input-group-sm mb-3 w-500" style={{ width: "350px" }}>
                                <div className="input-group-prepend">

                                    <select id="myDropdown" style={{ width: "380px" }} onChange={(e) => setDriverId(e.target.value)}>
                                        <option value="">Select Driver</option>
                                        {drivers ? drivers.map(item => (
                                            <option key={item._id} value={item._id}>
                                                {item.firstName + ' ' + item.lastName}
                                            </option>
                                        )) : <option>N/A</option>}
                                    </select>
                                </div>
                            </div>

                            <div className="input-group input-group-sm mb-3 w-100">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Pickup From</span>
                                </div>
                                <input type="text" className="form-control" aria-label="Name" value={orderPickup} onChange={(e) => setOrderPickup(e.target.value)} />
                            </div>
                            <div className="input-group input-group-sm mb-3 w-100">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Delivery Date</span>
                                </div>
                                <input type="date" className="form-control" aria-label="Name" value={orderDate} onChange={(e) => setOrderDate(e.target.value)} />
                            </div>

                            <button className="excel-btn col-reds w-10 pt-1 pb-1" type="submit" onClick={assignToDriver}>Submit</button>
                        </div>
                    </>
                        : <></>}
                </div>
            </Modal>
            <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginTop: users ? "3%" : "12%"
            }}>
                {/* <section className="excel-sec">
                    <div className="container tableContainer">
                        <div className=" mt-2 mb-3">
                            {users ?
                                <button className="excel-btn col-reds w-10 pt-1 pb-1">
                                    <CSVLink filename={"Student List.csv"} data={csvData}>Export Excel</CSVLink>
                                </button>
                                : <></>}
                        </div>
                    </div>
                </section> */}
                <section className="coupon-sec-2">
                    <div className="container tableContainer">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="card shadow mb-4">
                                    <div className="card-body">
                                        <div className="table-responsive" id="tableready">
                                            <table id="tableData" className="table table-bordered" style={{ width: '100%', textAlign: "center" }}>
                                                <thead>
                                                    {users ? (<tr>
                                                        <th>S.No</th>
                                                        <th>Order No</th>
                                                        <th>Customer Name</th>
                                                        <th>Address</th>
                                                        <th>Total Price</th>
                                                        <th>Order Placed Date</th>
                                                        <th>Order Delivered Date</th>
                                                        <th>Assigned To</th>
                                                        <th>Order Status</th>
                                                        {/* <th>Order Details</th> */}
                                                        <th>Action</th>
                                                    </tr>) : (<tr></tr>)}
                                                </thead>
                                                <tbody >
                                                    {users?.map((item, i) => (
                                                        <tr key={i}>
                                                            <td>{i + 1}</td>
                                                            {/* <td>{item?.firstName ? item?.firstName + ' ' + item?.lastName  : <>N/A</>}</td> */}
                                                            <td>{item?.order_number ? item?.order_number : <>N/A</>}</td>
                                                            <td>{item?.customer_details?.firstName ? item?.customer_details?.firstName + ' ' + item?.customer_details?.lastName : <>N/A</>}</td>
                                                            <td style={{
                                                                wordWrap: "break-word",
                                                                wordBreak: "break-all",
                                                                whiteSpace: "normal",
                                                            }}>{item?.shipping_address ? item?.shipping_address?.address : <>N/A</>}</td>
                                                            <td>{item?.total_price ? item?.total_price : <>N/A</>}</td>
                                                            <td>{moment(item?.order_placed_date ? item?.order_placed_date : <>N/A</>).format("DD-MMM-YYYY")}</td>
                                                            <td>{item?.order_delivery_date != null ? moment(item?.order_delivery_date).format("DD-MMM-YYYY") : <>N/A</>}</td>
                                                            {/* <td>{item?.driver_id != null ? item?.driver_id?.firstName + ' ' + item?.driver_id?.lastName : <>Unassigned</>}</td> */}
                                                            <td>{item?.driver_id != null ? item?.driver_id?.firstName + ' ' + item?.driver_id?.lastName : <button className="excel-btn col-reds w-10 pt-1 pb-1" onClick={() => viewModal(item._id, "driver")}>Assign Now</button>}</td>
                                                            <td>{item?.order_status ? item?.order_status : <>N/A</>}</td>
                                                            {/* <td>
                                                                <span className="edit-icon" >
                                                                    <span style={{ cursor: "pointer", fontWeight: "bold", margin: 10, fontSize: 13, }} onClick={() => viewModal(item, "userDetail")}  ><i className="fas fa-eye"></i> View</span>
                                                                </span>
                                                            </td> */}
                                                            <td>
                                                                <span className="edit-icon" >
                                                                <span style={{ cursor: "pointer", fontWeight: "bold", margin: 10, fontSize: 13, }} onClick={() => viewModal(item, "userDetail")}  ><i className="fas fa-eye"></i></span>
                                                                    <span style={{ cursor: "pointer", fontWeight: "bold", margin: 10 }} onClick={() => viewModal(item, "edit")}  ><i className="fas fa-edit"></i></span>
                                                                    {/* <span style={{ cursor: "pointer", fontWeight: "bold", margin: 10 }} onClick={() => blockUnblockAccount(item?._id)}  >{item?.is_blocked === 1 ? <i className="fa fa-unlock-alt"> UnBlock</i> : <i className="fa fa-solid fa-ban"> Block</i>}</span> */}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    )
                                                    )}
                                                </tbody>
                                                <tfoot>
                                                    {users ? (<tr>
                                                        <th>S.No</th>
                                                        <th>Order No</th>
                                                        <th>Customer Name</th>
                                                        <th>Address</th>
                                                        <th>Total Price</th>
                                                        <th>Order Placed Date</th>
                                                        <th>Order Delivered Date</th>
                                                        <th>Assigned To</th>
                                                        <th>Order Status</th>
                                                        {/* <th>Order Details</th> */}
                                                        <th>Action</th>
                                                    </tr>) : (<tr><th>{status == "loading" ? "Loading..." : "No Order Found"}</th></tr>)}
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
        </>
    )
}
export default OrderList
