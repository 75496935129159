import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { addDriver, blockunblock, deleteAccount, driverListing, getUserStatus } from '../store/slices/userSlice';
import { CSVLink } from "react-csv";
import $ from "jquery"
import 'datatables.net'
import Modal from 'react-modal';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        width: "30%",
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
Modal.setAppElement('#root');
const DriverList = () => {
    const [id, setId] = useState()
    const dispatch = useDispatch()
    const [users, setUsers] = useState(null)
    const status = useSelector(getUserStatus)
    const [userDetail, setUserDetail] = useState(null)
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalType, setModalType] = useState()
    const [email, setEmail] = useState()
    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()
    const [phoneNumber, setPhoneNumber] = useState()
    const [vehicleNumber, setVehicleNumber] = useState()
    const [licenseNumber, setLicenseNumber] = useState()
    const [password, setPassword] = useState()
    const [userImage, setUserImage] = useState()
    const [confirmPassword, setConfirmPassword] = useState()
    var csvData = [
        ["Name", "Email", "Phone Number", "Licence Number", "Vehicle Number", "Address", "Country", "State", "City", "Device Type"],
    ]
    users?.map((item) =>
        csvData.push([`${item?.name}`, `${item?.email}`, `${item?.phone_number}`, `${item?.license_number}`, `${item?.vehicle_number}`, `${item?.address}`, `${item?.country}`, `${item?.state}`, `${item?.city}`, `${item?.user_device_type}`])
    )

    function viewModal(item, type) {
        setIsOpen(true);
        if (type == "userDetail") {
            setUserDetail(item)
        } else if (type == "delete") {
            setId(item)
        }
        setModalType(type)
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }
    const handleImageUpload = (event) => {
        console.log('event.target :>> ', event.target.files);
        setUserImage(event.target.files[0]);
    };
    const accountDelete = async (id) => {
        try {
            await dispatch(deleteAccount(id)).unwrap()
            setIsOpen(false)
            $('#tableData')
                .DataTable().destroy();
            try {
                Users()
            } catch (rejectedValueOrSerializedError) {
                console.log(rejectedValueOrSerializedError)
            }
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError)
        }
    }
    const createDriver = async () => {
        try {
            const data = new FormData();
            data.append("user_image", userImage);
            data.append("email", email);
            data.append("firstName", firstName);
            data.append("lastName", lastName);
            data.append("phone_number", phoneNumber);
            data.append("vehicle_number", vehicleNumber);
            data.append("license_number", licenseNumber);
            data.append("password", password);
            data.append("confirm_password", confirmPassword);
            data.append("role", 'driver');

            await dispatch(addDriver(data)).unwrap()
            // await dispatch(addDriver({ user_image: userImage , email: email, firstName: firstName, lastName: lastName, phone_number: phoneNumber,vehicle_number: vehicleNumber, license_number: licenseNumber, password: password, confirm_password: confirmPassword, role: "driver" })).unwrap()
            if (users) {

                $('#tableData')
                    .DataTable().destroy();
            }
            Users()
            setModalType(null)
            setModalType(null)
            closeModal()
            setUserImage()
            email()
            firstName()
            lastName()
            phoneNumber()
            vehicleNumber()
            licenseNumber()
            password()
            confirmPassword()

        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError)
        }
    }
    const blockUnblockAccount = async (id) => {
        try {
            await dispatch(blockunblock(id)).unwrap()
            $('#tableData')
                .DataTable().destroy();
            try {
                Users()
            } catch (rejectedValueOrSerializedError) {
                console.log(rejectedValueOrSerializedError)
            }
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError)
        }
    }

    const Users = async () => {
        try {
            setUsers(null)
            const response = await dispatch(driverListing()).unwrap()
            // console.log(response.data)
            setUsers(response?.data)
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError)
        }
    }

    useEffect(() => {
        let mount = true
        if (mount) {
            Users();
        }
        return () => {
            mount = false
        }
    }, [])

    useEffect(() => {
        if (users) {
            $('#tableData')
                .DataTable({
                    lengthMenu: [10, 25, 50, 100, 200],
                    language: {
                        "emptyTable": "Drivers Not Found"
                    },
                    destroy: true,
                });
        }
    }, [users])



    return (
        <>
            <Modal
                closeTimeoutMS={500}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Change Password"
            >
                <div className='change-password-modal' id="exampleModalCenter" tabIndex={-1} aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{ display: "block", zIndex: 100 }}>
                    {modalType == "userDetail" ? <>
                        <p className="pass-text">User Details</p>
                        <button onClick={closeModal} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        <div className="modal-body">
                            <p > <b>Image:</b> {userDetail?.user_image ? <><img height="150px" width="150px" style={{ borderRadius: 5 }} src={`${process.env.REACT_APP_APIURL}${userDetail?.user_image}`}></img></> : <>No Image Found</>}</p>
                            <p > <b>Name:</b> {userDetail?.firstName ? userDetail?.firstName + ' ' + userDetail?.lastName : <>N/A</>}</p>
                            <p > <b>Email:</b> {userDetail?.email ? userDetail?.email : <>N/A</>}</p>
                            <p > <b>Phone Number:</b> {userDetail?.phone_number ? userDetail?.phone_number : <>N/A</>}</p>
                            <p > <b>Vehicle Number:</b> {userDetail?.vehicle_number ? userDetail?.vehicle_number : <>N/A</>}</p>
                            <p > <b>License Number:</b> {userDetail?.license_number ? userDetail?.license_number : <>N/A</>}</p>
                            <p > <b>Country:</b> {userDetail?.country ? userDetail?.country : <>N/A</>}</p>
                            <p > <b>State:</b> {userDetail?.state ? userDetail?.state : <>N/A</>}</p>
                            <p > <b>City:</b> {userDetail?.city ? userDetail?.city : <>N/A</>}</p>
                            {/* <p > <b>DOB:</b> {userDetail?.dob ? userDetail?.dob : <>N/A</>}</p> */}
                            <p > <b>Device Type:</b> {userDetail?.user_device_type ? userDetail?.user_device_type : <>N/A</>}</p>
                        </div>
                    </> : modalType == "create" ? <>
                        <p className="pass-text">Create Driver</p>
                        <button onClick={closeModal} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        <div className="modal-body">
                            <div>
                                <div className="input-group input-group-sm mb-3 w-100">
                                    <input
                                        type="file"
                                        className="form-control uploader"
                                        placeholder='Upload image'
                                        name="attachment[]"
                                        accept="image/png, image/jpeg"
                                        id="upload-img"
                                        onChange={handleImageUpload}
                                    />
                                </div>
                                {userImage && (
                                    <section>
                                        <div className="row" style={{ display: "inline" }}>
                                            <div
                                                className="col-lg-4 col-md-4 col-sm-6 col-12"
                                                style={{ display: "inline" }}
                                            >
                                                <div className="userCard" style={{ display: "inline" }}>
                                                    <div style={{ display: "inline" }}>

                                                        <img
                                                            src={URL.createObjectURL(userImage)}
                                                            alt={`Profile Image`}
                                                            style={{
                                                                width: "100px",
                                                                height: "100px",
                                                                borderRadius: "20px",
                                                                marginBottom: "10px",
                                                                marginRight: "10px",
                                                                marginLeft: "10px",
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                )}
                                </div>
                                <div className="input-group input-group-sm mb-3 w-100">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-sm">Email</span>
                                    </div>
                                    <input type="email" className="form-control" aria-label="Name" value={email} onChange={(e) => setEmail(e.target.value)} />
                                </div>
                                <div className="input-group input-group-sm mb-3 w-100">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-sm">First Name</span>
                                    </div>
                                    <input type="text" className="form-control" aria-label="Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                </div>
                                <div className="input-group input-group-sm mb-3 w-100">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-sm">Last Name</span>
                                    </div>
                                    <input type="text" className="form-control" aria-label="Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                </div>
                                <div className="input-group input-group-sm mb-3 w-100">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-sm">Phone Number</span>
                                    </div>
                                    <input type="text" className="form-control" aria-label="Name" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                                </div>
                                <div className="input-group input-group-sm mb-3 w-100">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-sm">Vehicle Number</span>
                                    </div>
                                    <input type="text" className="form-control" aria-label="Name" value={vehicleNumber} onChange={(e) => setVehicleNumber(e.target.value)} />
                                </div>
                                <div className="input-group input-group-sm mb-3 w-100">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-sm">Licence Number</span>
                                    </div>
                                    <input type="text" className="form-control" aria-label="Name" value={licenseNumber} onChange={(e) => setLicenseNumber(e.target.value)} />
                                </div>
                                <div className="input-group input-group-sm mb-3 w-100">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-sm">Password</span>
                                    </div>
                                    <input type="text" className="form-control" aria-label="Name" value={password} onChange={(e) => setPassword(e.target.value)} />
                                </div>
                                <div className="input-group input-group-sm mb-3 w-100">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-sm">Confirm Password</span>
                                    </div>
                                    <input type="text" className="form-control" aria-label="Name" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                                </div>


                                <button className="excel-btn col-reds w-10 pt-1 pb-1" type="submit" onClick={createDriver}>Add</button>
                            </div>
                        </> :
                        modalType == "delete" ? <>
                            <p className="pass-text">Delete Account Confirmation</p>
                            <button onClick={closeModal} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            <div className="modal-body">
                                <form >
                                    <div className="pass-form-wrap" style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                                        <div className="login-button mt-2" style={{ width: "40%" }}>
                                            <button type="button" onClick={() => accountDelete(id)} className="cta-btn col-reds w-100">Delete</button>
                                        </div>
                                        <div className="login-button mt-2" style={{ width: "40%" }} >
                                            <button type="button" onClick={closeModal} className="cta-btn col-reds w-100">Cancel</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </> : <></>}
                    </div>
            </Modal>
            <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginTop: users ? "3%" : "12%"
            }}>
                <section className="excel-sec">
                    <div className="container tableContainer">
                        <div className="d-flex justify-content-between mt-2 mb-3">
                            {users ? (
                                <button className="excel-btn col-reds w-10 pt-1 pb-1">
                                    <CSVLink filename={"Drivers List.csv"} data={csvData}>
                                        Export Excel
                                    </CSVLink>
                                </button>
                            ) : (
                                <></>
                            )}
                            <button className="excel-btn col-reds w-10 pt-1 pb-1 ml-auto" onClick={() => viewModal(null, "create")}>
                                Add Driver
                            </button>
                        </div>
                    </div>
                </section>
                <section className="coupon-sec-2">
                    <div className="container tableContainer">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="card shadow mb-4">
                                    <div className="card-body">
                                        <div className="table-responsive" id="tableready">
                                            <table id="tableData" className="table table-bordered" style={{ width: '100%', textAlign: "center" }}>
                                                <thead>
                                                    {users ? (<tr>
                                                        <th>S.No</th>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Phone Number</th>
                                                        <th>Address</th>
                                                        <th>Registration date</th>
                                                        <th>Profile Status</th>
                                                        <th>Action</th>
                                                    </tr>) : (<tr></tr>)}
                                                </thead>
                                                <tbody >
                                                    {users?.map((item, i) => (
                                                        <tr key={i}>
                                                            <td>{i + 1}</td>
                                                            <td>{item?.firstName ? item?.firstName + ' ' + item?.lastName : <>N/A</>}</td>
                                                            <td>{item?.email ? item?.email : <>N/A</>}</td>
                                                            <td>{item?.phone_number ? item?.phone_number : <>N/A</>}</td>
                                                            <td style={{
                                                                wordWrap: "break-word",
                                                                wordBreak: "break-all",
                                                                whiteSpace: "normal",
                                                            }}>{item?.address ? item?.address : <>N/A</>}</td>
                                                            <td>{moment(item?.createdAt ? item?.createdAt : <>N/A</>).format("DD-MMM-YYYY")}</td>
                                                            <td>{item?.user_is_profile_complete == 1 ? <>Completed</> : <>Incomplete</>}</td>
                                                            <td>
                                                                <span className="edit-icon" >
                                                                    {/* <span style={{ cursor: "pointer", fontWeight: "bold", margin: 10 }} onClick={() => viewModal(item?._id, "delete")}  ><i className="fas fa-trash-alt"></i> Delete</span> */}
                                                                    <span style={{ cursor: "pointer", fontWeight: "bold", margin: 10, fontSize: 13, }} onClick={() => viewModal(item, "userDetail")}  ><i className="fas fa-eye"></i></span>
                                                                    <span style={{ cursor: "pointer", fontWeight: "bold", margin: 10 }} onClick={() => blockUnblockAccount(item?._id)}  >{item?.is_blocked === 1 ? <i className="fa fa-unlock-alt"></i> : <i className="fa fa-solid fa-ban"></i>}</span>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    )
                                                    )}
                                                </tbody>
                                                <tfoot>
                                                    {users ? (<tr>
                                                        <th>S.No</th>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Phone Number</th>
                                                        <th>Address</th>
                                                        <th>Registration date</th>
                                                        <th>Profile Status</th>
                                                        <th>Action</th>
                                                    </tr>) : (<tr><th>{status == "loading" ? "Loading..." : "No Drivers Found"}</th></tr>)}
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
        </>
    )
}
export default DriverList
