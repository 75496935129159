import {
    createSlice,
    createAsyncThunk,
} from "@reduxjs/toolkit";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import { extraReducers } from "../reducer/userReducer"
import { io } from "socket.io-client"; 

axios.defaults.baseURL = process.env.REACT_APP_APIURL
const user = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : null
console.log(user)
axios.defaults.headers.common['Authorization'] = `Bearer ${user?.user_authentication}`;
const initialState = {
    status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
    user: user,
    terms: null,
    privacy: null,
    dashboard: null,
    socket:null


}
export const signinUser = createAsyncThunk('api/admin/login', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`api/admin/login`, bodyData)
        console.log(response)
        return response.data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error.response)
    }
})

export const dashboard = createAsyncThunk('api/admin/dashboard', async (bodyData = null, { rejectWithValue }) => {
    try {
        const response = await axios.get(`api/admin/dashboard`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

// export const recentCampaigns = createAsyncThunk('admin/recentCampaigns', async (bodyData = null, { rejectWithValue }) => {
//     try {
//         const response = await axios.get(`admin/recentCampaigns`)
//         return response.data
//     } catch (error) {
//         return rejectWithValue(error.response.data)
//     }
// })
export const driverListing = createAsyncThunk('api/admin/drivers-list', async (bodyData = null, { rejectWithValue }) => {
    try {
        const response = await axios.get(`api/admin/drivers-list`)
        console.log(response.data)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getAllDrivers = createAsyncThunk('api/admin/active-drivers', async (bodyData = null, { rejectWithValue }) => {
    try {
        const response = await axios.get(`api/admin/active-drivers`)
        console.log(response.data)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const addDriver = createAsyncThunk('api/admin/add-driver', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`api/admin/add-driver`, bodyData)
        return response
    } catch (error) {
        return rejectWithValue(error.response)
    }
})

export const getAllUsers = createAsyncThunk('api/admin/users', async (bodyData = null, { rejectWithValue }) => {
    try {
        const response = await axios.get(`api/admin/users`)
        console.log(response.data)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const getAllOrders = createAsyncThunk('api/admin/order-list', async (bodyData = null, { rejectWithValue }) => {
    try {
        const response = await axios.get(`api/admin/order-list`)
        console.log(response.data)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const getAllRetailors = createAsyncThunk('apiadmin/reatailor', async (bodyData = null, { rejectWithValue }) => {
    try {
        const response = await axios.get(`api/admin/reatailor`)
        console.log(response.data)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const getRestrictedArea = createAsyncThunk('admin/restricted-area-list', async (bodyData = null, { rejectWithValue }) => {
    try {
        const response = await axios.get(`admin/restricted-area-list`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const getAllPosts = createAsyncThunk('admin/get-all-posts', async (bodyData = null, { rejectWithValue }) => {
    try {
        const response = await axios.get(`admin/get-all-posts`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const getTutorialList = createAsyncThunk('api/hunting-tutorials-list', async (bodyData = null, { rejectWithValue }) => {
    try {
        const response = await axios.get(`api/hunting-tutorials-list`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const createNewTutor = createAsyncThunk('admin/add-tutor', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`admin/add-tutor`, bodyData)
        return response
    } catch (error) {
        return rejectWithValue(error.response)
    }
})

export const updateHourlyRate = createAsyncThunk('admin/update-hourly-rate', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`admin/update-hourly-rate`, bodyData)
        return response
    } catch (error) {
        return rejectWithValue(error.response)
    }
})
// export const getMarketPlace = createAsyncThunk('admin/allMarketPlaceAds', async (bodyData = null, { rejectWithValue }) => {
//     try {
//         const response = await axios.get(`admin/allMarketPlaceAds`)
//         return response.data
//     } catch (error) {
//         return rejectWithValue(error.response.data)
//     }
// })

// export const approveDisapproveAd = createAsyncThunk('admin/approveDisapproveAd', async (bodyData, { rejectWithValue }) => {
//     try {
//         const response = await axios.post(`admin/approveDisapproveAd/${bodyData.id}`, bodyData)
//         return response.data
//     } catch (error) {
//         return rejectWithValue(error.response.data)
//     }
// })

// export const getReportedPosts = createAsyncThunk('admin/getReportedPosts', async (bodyData = null, { rejectWithValue }) => {
//     try {
//         const response = await axios.get(`admin/getReportedPosts`)
//         return response.data
//     } catch (error) {
//         return rejectWithValue(error.response.data)
//     }
// })

// export const approveDisapproveReport = createAsyncThunk('admin/approveDisapproveReport', async (bodyData, { rejectWithValue }) => {
//     try {
//         const response = await axios.post(`admin/approveDisapproveReport/${bodyData.id}`, bodyData)
//         return response.data
//     } catch (error) {
//         return rejectWithValue(error.response.data)
//     }
// })
export const assignDriver = createAsyncThunk('api/admin/assign-driver', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`api/admin/assign-driver`, bodyData)
        return response
    } catch (error) {
        return rejectWithValue(error.response)
    }
})

export const addCategory = createAsyncThunk('api/admin/add-category', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`api/admin/add-category`, bodyData)
        return response
    } catch (error) {
        return rejectWithValue(error.response)
    }
})
export const updateCategory = createAsyncThunk('api/admin/update-category', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`api/admin/update-category`, bodyData)
        return response
    } catch (error) {
        return rejectWithValue(error.response)
    }
})

export const addSubCategory = createAsyncThunk('api/admin/add-sub-category', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`api/admin/add-sub-category`, bodyData)
        return response
    } catch (error) {
        return rejectWithValue(error.response)
    }
})
export const updateSubCategory = createAsyncThunk('api/admin/update-sub-category', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`api/admin/update-sub-category`, bodyData)
        return response
    } catch (error) {
        return rejectWithValue(error.response)
    }
})

export const deleteCategory = createAsyncThunk('api/admin/delete-category', async (id, { rejectWithValue }) => {
    try {
        let category_id = id
        console.log(id)
        const response = await axios.delete(`api/admin/delete-category/${category_id}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const deleteSubCategory = createAsyncThunk('api/admin/delete-sub-category', async (id, { rejectWithValue }) => {
    try {
        let sub_category_id = id
        const response = await axios.delete(`api/admin/delete-sub-category/${sub_category_id}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const deleteProduct = createAsyncThunk('api/admin/delete-product', async (id, { rejectWithValue }) => {
    try {
        let product_id = id
        const response = await axios.delete(`api/admin/delete-product/${product_id}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const addProduct = createAsyncThunk('api/admin/add-product', async (data, { rejectWithValue }) => {
    try {
        // console.log('bosydata--->',data )
        const response = await axios.post(`api/admin/add-product`, data)
        return response
    } catch (error) {
        return rejectWithValue(error.response)
    }
})
export const updateProduct = createAsyncThunk('api/admin/update-product', async (data, { rejectWithValue }) => {
    try {
        console.log('bosydata--->', data)
        const response = await axios.post(`api/admin/update-product`, data)
        return response
    } catch (error) {
        return rejectWithValue(error.response)
    }
})
export const updateOrder = createAsyncThunk('api/admin/update-order', async (data, { rejectWithValue }) => {
    try {
        // console.log('bosydata--->',data )
        const response = await axios.post(`api/admin/update-order`, data)
        return response
    } catch (error) {
        return rejectWithValue(error.response)
    }
})

export const addTutorial = createAsyncThunk('admin/add-tutorial', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`admin/add-tutorial`, bodyData)
        return response
    } catch (error) {
        return rejectWithValue(error.response)
    }
})

export const addRestrictedArea = createAsyncThunk('admin/add-restricted-area', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`admin/add-restricted-area`, bodyData)
        return response
    } catch (error) {
        return rejectWithValue(error.response)
    }
})

export const deletePost = createAsyncThunk('admin/delete-post', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`admin/delete-post/${id}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const deleteRestrictedArea = createAsyncThunk('admin/delete-restricted-area', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`admin/delete-restricted-area/${id}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const deleteHuntingTutorial = createAsyncThunk('admin/delete-tutorial', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`admin/delete-tutorial/${id}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const deleteAccount = createAsyncThunk('admin/deleteAccount', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`admin/deleteAccount/${id}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const blockunblock = createAsyncThunk('api/admin/blockunblock', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.get(`api/admin/blockunblock/${id}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const approveProfile = createAsyncThunk('api/admin/approve-profile', async (bodyData, { rejectWithValue }) => {
    try {
        // const response = await axios.get(`api/admin/approve-profile/${id}`)
        const response = await axios.post(`api/admin/approve-profile`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const updatePassword = createAsyncThunk('api/admin/updatePassword', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`api/admin/updatePassword`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})


export const updateTerms = createAsyncThunk('api/admin/updateContent/terms_and_conditions', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`api/admin/updateContent/terms_and_conditions`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const updateShippingPolicy = createAsyncThunk('api/admin/updateContent/delivery_and_shipping', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`api/admin/updateContent/delivery_and_shipping`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const updatePrivacy = createAsyncThunk('api/admin/updateContent/privacy_policy', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`api/admin/updateContent/privacy_policy`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const updateReturnPolicy = createAsyncThunk('api/admin/updateContent/return_policy', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`api/admin/updateContent/return_policy`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const chatList = createAsyncThunk('api/admin/chat-list', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`api/admin/chat-list`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const userLogout = createAsyncThunk('api/admin/signout', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`api/admin/signout`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})



export const terms = createAsyncThunk('api/admin/content/terms_and_conditions', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.get(`api/admin/content/terms_and_conditions`)
        console.log(response.data.data.content)
        return response.data.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const privacy = createAsyncThunk('api/admin/content/privacy_policy', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.get(`api/admin/content/privacy_policy`)
        console.log(response.data.data.content)
        return response.data.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const returnPolicy = createAsyncThunk('api/admin/content/return_policy', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.get(`api/admin/content/return_policy`)
        console.log(response.data.data.content)
        return response.data.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const shippingPolicy = createAsyncThunk('api/admin/content/delivery_and_shipping', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.get(`api/admin/content/delivery_and_shipping`)
        console.log(response.data.data.content)
        return response.data.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const addBanner = createAsyncThunk('api/admin/add-banner', async (data, { rejectWithValue }) => {
    try {
        // console.log('bosydata--->',data )
        const response = await axios.post(`api/admin/add-banner`, data)
        return response
    } catch (error) {
        return rejectWithValue(error.response)
    }
})

export const deleteBanner = createAsyncThunk('api/admin/delete-banner', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`api/admin/delete-banner/${id}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const notification = createAsyncThunk('api/admin/send-notification', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`api/admin/send-notification`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const email = createAsyncThunk('api/admin/send-email', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`api/admin/send-email`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

// export const addCategory = createAsyncThunk('admin/categories', async (category, { rejectWithValue }) => {
//     try {

//         const response = await axios.post(`admin/categories`, category)
//         return response.data
//     } catch (error) {
//         return rejectWithValue(error.response.data)
//     }
// })


export const getCategory = createAsyncThunk('api/admin/category-list', async (category, { rejectWithValue }) => {
    try {

        const response = await axios.get(`api/admin/category-list`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const getSubCategory = createAsyncThunk('api/admin/sub-category-list', async (category, { rejectWithValue }) => {
    try {

        const response = await axios.get(`api/admin/sub-category-list`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const getProduct = createAsyncThunk('api/admin/product-list', async (category, { rejectWithValue }) => {
    try {

        const response = await axios.get(`api/admin/product-list`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const getbanners = createAsyncThunk('api/admin/banner-list', async (banner, { rejectWithValue }) => {
    try {
        const response = await axios.get(`api/admin/banner-list`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
// export const deleteCategory = createAsyncThunk('admin/deletecategory', async (id, { rejectWithValue }) => {
//     try {
//         const response = await axios.delete(`admin/deletecategory/${id}`)
//         return response.data
//     } catch (error) {
//         return rejectWithValue(error.response.data)
//     }
// })


const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        token: (state) => {
            const socket = io(process.env.REACT_APP_APIURL);
            if(socket){
                state.socket=socket
            }
            var user = JSON.parse(localStorage.getItem('user'));
            if (user) {
                state.user = user
                state.token = user.user_authentication
            }
        },
        sessionOut: (state) => {
            state.error = null
        state.user = null
        state.userProfile = null
        state.token= null
        state.forgot = null
        }
    },
    extraReducers
})
export const getUserStatus = (state) => state?.users?.status;
export const getUser = (state) => state?.users?.user;
export const getSocket = (state) => state?.users?.socket;
export const getBannerStatus = (state) => state?.users?.getbanners;
export const getUserError = (state) => state?.users?.error;
export const getUsertoken = (state) => state?.users?.user?.user_authentication;
export const getProfile = (state) => state?.users?.user;
export const getTerms = (state) => state?.users?.terms;
export const getReturnPolicy = (state) => state?.users?.returnPolicy;
export const getPrivacy = (state) => state?.users?.privacy;
export const getShipping = (state) => state?.users?.shippingPolicy;
export const getAllCategories = (state) => state?.users?.categories;
export const getAllCharges = (state) => state?.users?.charges;
export const getDashboard = (state) => state?.users?.dashboard;
export const getareaChart = (state) => state?.users?.areaChart;
export const getlineChart = (state) => state?.users?.lineChart;
export const getcampaigns = (state) => state?.users?.campaigns;

export const { token } = userSlice.actions

export default userSlice.reducer