import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { addSubCategory, deleteSubCategory, updateSubCategory, getCategory, getSubCategory, getUserStatus } from '../store/slices/userSlice';
import { CSVLink } from "react-csv";
import $ from "jquery"
import 'datatables.net'
import Modal from 'react-modal';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        width: "30%",
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
Modal.setAppElement('#root');
const SubCategoryList = () => {
    const [id, setId] = useState()
    const dispatch = useDispatch()
    const [users, setUsers] = useState(null)
    const [category, setCategory] = useState(null)
    const status = useSelector(getUserStatus)
    const [userDetail, setUserDetail] = useState(null)
    const [attachment, setAttachment] = useState();
    const [title, setTitle] = useState("");
    const [catId, setCatId] = useState("");
    const [catName, setCatName] = useState();
    const [description, setDescription] = useState("");
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalType, setModalType] = useState()
    function viewModal(item, type) {
        setIsOpen(true);
        if (type == "userDetail") {

            setUserDetail(item)
        } else if (type == "delete") {
            setId(item)
        }
        else if (type == "create") {
            // getCategory()
        }
        else if (type == "edit") {
            setId(item?._id)
            setTitle(item?.name)
            setCatName(item?.category?.name)
            setCatId(item?.category?._id)
        }
        setModalType(type)
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
        setAttachment()
        setTitle(null)
        setDescription(null)
    }
    console.log('imagessssssssssssss', attachment)
    function AttachmenthandleChange(e) {
        e.preventDefault();
        console.log("e.target.files[0]", e.target.files);
        setAttachment(e.target.files[0]);
    }

    const tutorialDelete = async (id) => {
        try {
            await dispatch(deleteSubCategory(id)).unwrap()
            setIsOpen(false)
            $('#tableData')
                .DataTable().destroy();
            try {
                Users()
            } catch (rejectedValueOrSerializedError) {
                console.log(rejectedValueOrSerializedError)
            }
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError)
        }
    }

    const createTutorial = async () => {
        try {
            console.log("working")
            console.log(title, catId)
            // const data = new FormData();
            // data.append("title", title);
            // data.append("description", description);
            // data.append("tutorial_video", attachment);

            await dispatch(addSubCategory({ name: title, category_id: catId })).unwrap()
            if (users) {

                $('#tableData')
                    .DataTable().destroy();
            }
            closeModal()
            Users()
            setModalType(null)
            setTitle(null)
            setModalType(null)
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError)
        }
    }
    const subcategoryUpdate = async () => {
        try {
            // console.log("working")
            // const data = new FormData();
            // data.append("title", title);
            // data.append("description", description);
            // data.append("tutorial_video", attachment);

            await dispatch(updateSubCategory({  sub_category_id : id, name: title, category_id: catId })).unwrap()
            if (users) {

                $('#tableData')
                    .DataTable().destroy();
            }
            closeModal()
            Users()
            setModalType(null)
            setTitle(null)
            setModalType(null)
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError)
        }
    }
    // const getCategory = async () => {
    //     try {
    //         const categoryData = await dispatch(getCategory()).unwrap()
    //         console.log(categoryData.data)
    //         setCategory(categoryData?.data)
    //     } catch (rejectedValueOrSerializedError) {
    //         console.log(rejectedValueOrSerializedError)
    //     }
    // }

    const Users = async () => {
        try {
            setUsers(null)
            const categoryData = await dispatch(getCategory()).unwrap()
            setCategory(categoryData?.data)

            const response = await dispatch(getSubCategory()).unwrap()
            console.log(response.data)
            setUsers(response?.data)

        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError)
        }
    }

    useEffect(() => {
        let mount = true
        if (mount) {
            Users();
            // getCategory()
        }
        return () => {
            mount = false
        }
    }, [])

    useEffect(() => {
        if (users) {
            $('#tableData')
                .DataTable({
                    lengthMenu: [10, 25, 50, 100, 200],
                    language: {
                        "emptyTable": "Sub Categories Not Found"
                    },
                    destroy: true,
                });
        }
    }, [users])



    return (
        <>
            <Modal
                closeTimeoutMS={500}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Change Password"
            >
                <div className='change-password-modal' id="exampleModalCenter" tabIndex={-1} aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{ display: "block", zIndex: 100 }}>
                    {modalType == "delete" ? <>
                        <p className="pass-text">Are you sure you want to delete sub category? </p>
                        <button onClick={closeModal} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        <div className="modal-body">
                            <form >
                                <div className="pass-form-wrap" style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                                    <div className="login-button mt-2" style={{ width: "40%" }}>
                                        <button type="button" onClick={() => tutorialDelete(id)} className="cta-btn col-reds w-100">Delete</button>
                                    </div>
                                    <div className="login-button mt-2" style={{ width: "40%" }} >
                                        <button type="button" onClick={closeModal} className="cta-btn col-reds w-100">Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </> : modalType == "create" ? <>
                        <p className="pass-text">Add Sub Category</p>
                        <button onClick={closeModal} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        <div className="modal-body">
                            <div className="input-group input-group-sm mb-3 w-500" style={{ width: "350px" }}>
                                <div className="input-group-prepend">

                                    <select id="myDropdown" style={{ width: "380px" }} onChange={(e) => setCatId(e.target.value)}>
                                        <option value="">Select category</option>
                                        {category ? category.map(item => (
                                            <option key={item._id} value={item._id}>
                                                {item.name}
                                            </option>
                                        )) : <option>N/A</option>}
                                    </select>
                                </div>
                            </div>

                            <div className="input-group input-group-sm mb-3 w-100">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Sub Category title</span>
                                </div>
                                <input type="text" className="form-control" aria-label="Name" value={title} onChange={(e) => setTitle(e.target.value)} />
                            </div>

                            <button className="excel-btn col-reds w-10 pt-1 pb-1" type="submit" onClick={createTutorial}>Add</button>
                        </div>
                    </> : modalType == "edit" ? <>
                        <p className="pass-text">Update Sub Category</p>
                        <button onClick={closeModal} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        <div className="modal-body">
                            <div className="input-group input-group-sm mb-3 w-500" style={{ width: "350px" }}>
                                <div className="input-group-prepend">

                                    <select id="myDropdown" style={{ width: "380px" }} onChange={(e) => setCatId(e.target.value)}>
                                        <option value={catId}>{catName}</option>
                                        {category
                                            ? category
                                                .filter(item => item._id !== catId) // Exclude the currently selected catId
                                                .map(item => (
                                                    <option key={item._id} value={item._id}>
                                                        {item.name}
                                                    </option>
                                                ))
                                            : <option>N/A</option>
                                        }
                                    </select>

                                </div>
                            </div>

                            <div className="input-group input-group-sm mb-3 w-100">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Sub Category title</span>
                                </div>
                                <input type="text" className="form-control" aria-label="Name" value={title} onChange={(e) => setTitle(e.target.value)} />
                            </div>

                            <button className="excel-btn col-reds w-10 pt-1 pb-1" type="submit" onClick={subcategoryUpdate}>Update</button>
                        </div>
                    </> : <></>}
                </div>
            </Modal>
            <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginTop: users ? "3%" : "12%"
            }}>
                <section className="excel-sec">
                    <div className="container tableContainer">
                        <div className="  mt-2 mb-3" >
                            <button className="excel-btn col-reds w-10 pt-1 pb-1" onClick={() => viewModal(null, "create")}>
                                Add Sub Category
                            </button>

                        </div>
                    </div>
                </section>
                <section className="coupon-sec-2">
                    <div className="container tableContainer">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="card shadow mb-4">
                                    <div className="card-body">
                                        <div className="table-responsive" id="tableready">
                                            <table id="tableData" className="table table-bordered" style={{ width: '100%', textAlign: "center" }}>
                                                <thead>
                                                    {users ? (<tr>
                                                        <th>S.No</th>
                                                        <th>Title</th>
                                                        <th>Category Name</th>
                                                        <th>Creation date</th>
                                                        {/* <th>Detail</th> */}
                                                        <th>Action</th>
                                                    </tr>) : (<tr></tr>)}
                                                </thead>
                                                <tbody >
                                                    {users?.map((item, i) => (
                                                        <tr key={i}>
                                                            <td>{i + 1}</td>
                                                            <td>{item?.name ? item?.name : <>N/A</>}</td>
                                                            <td>{item?.category ? item?.category?.name : <>N/A</>}</td>
                                                            {/* <td style={{
                                                            wordWrap: "break-word",
                                                            wordBreak: "break-all",
                                                            whiteSpace: "normal",
                                                        }}>{item?.description ? item?.description : <>N/A</>}</td> */}
                                                            <td>{moment(item?.createdAt ? item?.createdAt : <>N/A</>).format("DD-MMM-YYYY")}</td>
                                                            {/* <td>
                                                                <span className="edit-icon" >
                                                                    <span style={{ cursor: "pointer", fontWeight: "bold", margin: 10, fontSize: 13, }} onClick={() => viewModal(item, "userDetail")}  ><i className="fas fa-eye"></i> View</span>
                                                                </span>
                                                            </td> */}
                                                            <td>
                                                                <span className="edit-icon" >
                                                                    <span style={{ cursor: "pointer", fontWeight: "bold", margin: 10 }} onClick={() => viewModal(item, "edit")}  ><i className="fas fa-edit"></i></span>
                                                                    <span style={{ cursor: "pointer", fontWeight: "bold", margin: 10 }} onClick={() => viewModal(item?._id, "delete")}  ><i className="fas fa-trash-alt"></i></span>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    )
                                                    )}
                                                </tbody>
                                                <tfoot>
                                                    {users ? (<tr>
                                                        <th>S.No</th>
                                                        <th>Title</th>
                                                        <th>Category Name</th>
                                                        <th>Creation date</th>
                                                        {/* <th>Detail</th> */}
                                                        <th>Action</th>
                                                    </tr>) : (<tr><th>{status == "loading" ? "Loading..." : "No Sub Category Found"}</th></tr>)}
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
        </>
    )
}
export default SubCategoryList
