import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { addProduct, deleteProduct, updateProduct, getProduct, getUserStatus, getSubCategory } from '../store/slices/userSlice';
import { CSVLink } from "react-csv";
import $ from "jquery"
import 'datatables.net'
import Modal from 'react-modal';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        width: "30%",
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
Modal.setAppElement('#root');
const ProductList = () => {
    const [id, setId] = useState()
    const dispatch = useDispatch()
    const [users, setUsers] = useState(null)
    const status = useSelector(getUserStatus)
    const [userDetail, setUserDetail] = useState(null)
    const [attachments, setAttachments] = useState([]);
    const [subcat, setSubCat] = useState("");
    const [subCatId, setSubCatId] = useState("");
    const [subCatName, setSubCatName] = useState();
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState();
    const [price, setPrice] = useState("");
    const [retailPrice, setRetailPrice] = useState("");
    const [quantity, setQuantity] = useState("");
    const [discountPrice, setDiscountPice] = useState("");
    const [weight, setWeight] = useState("");
    const [length, setLength] = useState("");
    const [height, setHeight] = useState("");
    const [width, setWidth] = useState("");
    // const [isDiscounted, setIsDiscounted] = useState();
    const [retailDiscountedPrice, setRetailDiscountedPrice] = useState("");
    const [sku, setSku] = useState("");
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalType, setModalType] = useState()
    const [prev_picture, setprev_picture] = useState([]);
    const [productImage, setProductImage] = useState([]);
    const [isDiscountActive, setisDiscountActive] = useState(false);
    const [isRetailDiscountActive, setisRetailDiscountActive] = useState(false);
    const [isAvailable, setisAvailable] = useState(false);
    const [productStatusList, setProductStatusList] = useState(["in-stock", "out-stock", "back-order"])
    const [productStatus, setProductStatus] = useState("")
    const [checkBoxes, setCheckBoxes] = useState({
        arrival: false,
        promotion: false,
        unlimited: false,
        backOrder: false,
        // checkBox3: false,
    });
    const handleToggle = () => {
        setisDiscountActive(!isDiscountActive);
    };
    const handleToggleReatail = () => {
        setisRetailDiscountActive(!isRetailDiscountActive);
    };
    const handleToggleAvailable = () => {
        setisAvailable(!isAvailable);
    };
    const handleCheckBoxChange = (checkBoxName) => {
        setCheckBoxes((prevCheckBoxes) => ({
            ...prevCheckBoxes,
            [checkBoxName]: !prevCheckBoxes[checkBoxName],
        }));
    };
    function viewModal(item, type) {
        setIsOpen(true);
        if (type == "userDetail") {
            setUserDetail(item)
        } else if (type == "delete") {
            setId(item)
        }
        else if (type == "edit") {
            setId(item?._id)
            setSubCatId(item?.category?._id)
            setSubCatName(item?.category?.name)
            setTitle(item?.title ? item?.title : '')
            setSku(item?.sku ? item?.sku : '')
            setDiscountPice(item?.discounted_price)
            setRetailDiscountedPrice(item?.retailer_discounted_price)
            setQuantity(item?.quantity)
            setRetailPrice(item?.retailer_price)
            setPrice(item?.price)
            setDescription(item?.description ? item?.description : '')
            setprev_picture(item?.prod_images?.length > 0 ? item.prod_images : [])
            setProductStatus(item?.product_status);
            setCheckBoxes(prevState => ({
                ...prevState,
                arrival: item?.is_new_arrival || false,
                promotion: item?.is_promotional || false,
                unlimited: item?.is_stock_unlimited || false,
                backOrder: item?.allow_back_order || false,
            }));
            //    setCheckBoxes.arrival(item?.is_new_arrival);
            //     setCheckBoxes.promotion(item?.is_promotional);
            //     setCheckBoxes.unlimited(item?.is_stock_unlimited);
            //     setCheckBoxes.backOrder(item?.allow_back_order);
            setLength(item?.length ? item?.length : '' );
            setWidth(item?.width ? item?.width : '');
            setHeight(item?.height ? item?.height : '');
            setWeight(item?.weight ? item?.weight : '');
            if (item?.is_discounted == 1) {
                setisDiscountActive(true)
            }
            if (item?.is_retail_discounted == 1) {
                setisRetailDiscountActive(true)
            }
            // if (item?.is_available == 1) {
            //     setisAvailable(true)
            // }
        }
        setModalType(type)
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
        setAttachments([])
        setTitle("")
        setDescription("")
        setTitle("")
        setAttachments([])
        setSubCatId("")
        setSku("")
        setDiscountPice()
        setRetailDiscountedPrice()
        setQuantity("")
        setRetailPrice("")
        setPrice("")
        // setDescription("")
        setSubCatName("")
        setProductImage([])
        setprev_picture([])
    }

    const handleImageUpload = (event) => {
        // console.log('event.target :>> ', event.target.files);
        const selectedImages = event.target.files;
        const imagesArray = [];

        for (let i = 0; i < selectedImages.length; i++) {
            imagesArray.push(selectedImages[i]);
        }
        setProductImage(productImage.concat(imagesArray));
    };
    // console.log(productImage)
    const handleImageRemovePrev = (e, index) => {
        e.preventDefault();
        console.log("workingggg");
        const updatedImages = [...prev_picture];
        updatedImages.splice(index, 1);
        setprev_picture(updatedImages);
    };

    const handleImageRemove = (e, index) => {
        e.preventDefault();
        console.log("workingggg");
        const updatedImages = [...productImage];
        updatedImages.splice(index, 1);

        setProductImage(updatedImages);
    };

    const handleFileChange = (e) => {
        const selectedFiles = e.target.files;
        const newAttachments = [];

        for (let i = 0; i < selectedFiles.length; i++) {
            newAttachments.push(selectedFiles[i]);
        }

        setAttachments(newAttachments);
    };

    const tutorialDelete = async (id) => {
        try {
            await dispatch(deleteProduct(id)).unwrap()
            setIsOpen(false)
            $('#tableData')
                .DataTable().destroy();
            try {
                Users()
            } catch (rejectedValueOrSerializedError) {
                console.log(rejectedValueOrSerializedError)
            }
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError)
        }
    }

    const createTutorial = async () => {
        try {
            // e.preventDefault();
            console.log('imagessssssssssssss', attachments)
            console.log("working")
            const data = new FormData();
            data.append("sub_category_id", subCatId);
            data.append("title", title);
            data.append("description", description);
            // data.append("prod_image", attachments);
            data.append("sku", sku);
            data.append("price", price);
            data.append("retailer_price", retailPrice);
            data.append("quantity", quantity);
            data.append("discounted_price", discountPrice);
            data.append("retailer_discounted_price", retailDiscountedPrice);
            data.append("product_status", productStatus);
            data.append("is_new_arrival", checkBoxes?.arrival);
            data.append("is_promotional", checkBoxes?.promotion);
            data.append("is_stock_unlimited", checkBoxes?.unlimited);
            data.append("allow_back_order", checkBoxes?.backOrder);
            data.append("length", length);
            data.append("width", width);
            data.append("height", height);
            data.append("weight", weight);
            for (let i = 0; i < productImage.length; i++) {
                data.append('prod_image', productImage[i])
            }
            // data.append("tutorial_video", quantity);
            // console.log(data)
            // return
            await dispatch(addProduct(data)).unwrap()
            if (users) {

                $('#tableData')
                    .DataTable().destroy();
            }
            closeModal()
            Users()
            setModalType(null)
            setTitle("")
            // setAttachments([])
            setSubCatId("")
            setSku("")
            setDiscountPice()
            setQuantity("")
            setRetailPrice("")
            setPrice("")
            setDescription("")
            setRetailDiscountedPrice()
            setProductImage([])
            setprev_picture([])
            setProductStatus()
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError)
        }
    }

    const productUpdate = async () => {
        try {
            // console.log("working")
            // const data = new FormData();
            // data.append("title", title);
            // data.append("description", description);
            // data.append("tutorial_video", attachment);
            // await dispatch(updateProduct({
            //     product_id: id,
            //     title: title,
            //     sub_category_id: subCatId,
            //     description: description,
            //     price: price,
            //     retailer_price: retailPrice,
            //     quantity: quantity
            // })).unwrap()
            const data = new FormData();
            data.append("product_id", id);
            data.append("sub_category_id", subCatId);
            data.append("title", title);
            data.append("description", description);
            // data.append("prod_image", attachments);
            data.append("sku", sku);
            data.append("price", price);
            data.append("retailer_price", retailPrice);
            data.append("quantity", quantity);
            data.append("discounted_price", discountPrice);
            data.append("retailer_discounted_price", retailDiscountedPrice);
            data.append("is_available", isAvailable == true ? 1 : 0);
            data.append("is_discounted", isDiscountActive == true ? 1 : 0);
            data.append("is_retail_discounted", isRetailDiscountActive == true ? 1 : 0);
            // data.append("product_status", productStatus);
            data.append("prevImg", JSON.stringify(prev_picture));
            data.append("product_status", productStatus);
            data.append("is_new_arrival", checkBoxes?.arrival);
            data.append("is_promotional", checkBoxes?.promotion);
            data.append("is_stock_unlimited", checkBoxes?.unlimited);
            data.append("allow_back_order", checkBoxes?.backOrder);
            data.append("length", length);
            data.append("width", width);
            data.append("height", height);
            data.append("weight", weight);
            productImage.forEach((item) => {
                data.append("prod_image", item);

            });
            console.log("updateProdutc", data);
            await dispatch(updateProduct(data)).unwrap()
            if (users) {

                $('#tableData')
                    .DataTable().destroy();
            }
            closeModal()
            Users()
            setModalType(null)
            setTitle("")
            setAttachments([])
            setSubCatId("")
            setSku("")
            setDiscountPice("")
            setQuantity("")
            setRetailPrice()
            setPrice("")
            setDescription("")
            setRetailDiscountedPrice("")
            setProductImage([])
            setprev_picture([])
            isDiscountActive(false)
            isRetailDiscountActive(false)
            setProductStatus()
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError)
        }
    }

    const Users = async () => {
        try {
            setUsers(null)
            const response = await dispatch(getProduct()).unwrap()
            console.log(response.data)
            setUsers(response?.data)
            const categoryData = await dispatch(getSubCategory()).unwrap()
            console.log(categoryData.data)
            setSubCat(categoryData?.data)
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError)
        }
    }

    useEffect(() => {
        let mount = true
        if (mount) {
            Users();
        }
        return () => {
            mount = false
        }
    }, [])
    console.log("prev_picture :>> ", prev_picture);
    useEffect(() => {
        if (users) {
            $('#tableData')
                .DataTable({
                    lengthMenu: [10, 25, 50, 100, 200],
                    language: {
                        "emptyTable": "Users Not Found"
                    },
                    destroy: true,
                });
        }
    }, [users])



    return (
        <>
            <Modal
                closeTimeoutMS={500}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Change Password"
            >
                <div className='change-password-modal' id="exampleModalCenter" tabIndex={-1} aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{ display: "block", zIndex: 100 }}>
                    {modalType == "delete" ? <>
                        <p className="pass-text">Are you sure you want to delete product? </p>
                        <button onClick={closeModal} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        <div className="modal-body">
                            <form >
                                <div className="pass-form-wrap" style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                                    <div className="login-button mt-2" style={{ width: "40%" }}>
                                        <button type="button" onClick={() => tutorialDelete(id)} className="cta-btn col-reds w-100">Delete</button>
                                    </div>
                                    <div className="login-button mt-2" style={{ width: "40%" }} >
                                        <button type="button" onClick={closeModal} className="cta-btn col-reds w-100">Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </> : modalType == "create" ? <>
                        <p className="pass-text">Add Product</p>
                        <button onClick={closeModal} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        <div className="modal-body">
                            <div>
                                <div className="input-group input-group-sm mb-3 w-100">
                                    <input
                                        type="file"
                                        className="form-control uploader"
                                        placeholder='Upload image'
                                        name="attachment[]"
                                        multiple
                                        accept="image/png, image/jpeg"
                                        id="upload-img"
                                        onChange={handleImageUpload}
                                    />
                                </div>
                                {productImage.length > 0 && (
                                    <section>
                                        <div className="row" style={{ display: "inline" }}>
                                            <div
                                                className="col-lg-4 col-md-4 col-sm-6 col-12"
                                                style={{ display: "inline" }}
                                            >
                                                <div className="userCard" style={{ display: "inline" }}>
                                                    <div style={{ display: "inline" }}>
                                                        {productImage?.map((image, index) => (
                                                            <>
                                                                <div
                                                                    style={{
                                                                        display: "inline",
                                                                        position: "relative",
                                                                    }}
                                                                    key={index}
                                                                >
                                                                    <img
                                                                        src={URL.createObjectURL(image)}
                                                                        alt={`Image ${index}`}
                                                                        style={{
                                                                            width: "100px",
                                                                            height: "100px",
                                                                            borderRadius: "20px",
                                                                            marginBottom: "10px",
                                                                            marginRight: "10px",
                                                                            marginLeft: "10px",
                                                                        }}
                                                                    />
                                                                    <button
                                                                        style={{
                                                                            borderRadius: "100%",
                                                                            position: "absolute",
                                                                            right: "20px",
                                                                            marginTop: "2px",
                                                                        }}
                                                                        onClick={(e) => handleImageRemove(e, index)}
                                                                    >
                                                                        <i
                                                                            style={{
                                                                                color: "red",
                                                                                backgroundColor: "white",
                                                                            }}
                                                                            className="fas fa-trash-alt"
                                                                        ></i>
                                                                    </button>
                                                                </div>
                                                            </>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </section>
                                )}
                                {/* {attachments.length > 0 && (
                                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: "10px" }}>
                                        {attachments.map((file, index) => (
                                            <div key={index} style={{ marginRight: '10px' }}>

                                                <img
                                                    src={URL.createObjectURL(file)}
                                                    alt={`Image ${index}`}
                                                    height={"50px"}
                                                    width={"50px"}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                )} */}

                            </div>

                            <div className="input-group input-group-sm mb-3 w-500" style={{ width: "350px" }}>
                                <div className="input-group-prepend">

                                    <select id="myDropdown" style={{ width: "380px" }} onChange={(e) => setSubCatId(e.target.value)}>
                                        <option value="">Select Sub Category</option>
                                        {subcat.length > 0 ? subcat.map(item => (
                                            <option key={item._id} value={item._id}>
                                                {item.name}
                                            </option>
                                        )) : <option>N/A</option>}
                                    </select>
                                </div>
                            </div>

                            <div className="input-group input-group-sm mb-3 w-100">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Title</span>
                                </div>
                                <input type="text" className="form-control" aria-label="Name" value={title} onChange={(e) => setTitle(e.target.value)} />
                            </div>
                            <div className="input-group input-group-sm mb-3 w-100">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Description</span>
                                </div>
                                <textarea type="text" className="form-control" aria-label="Name" value={description} onChange={(e) => setDescription(e.target.value)} />
                            </div>
                            <div className="input-group input-group-sm mb-3 w-100">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">SKU</span>
                                </div>
                                <input type="text" className="form-control" aria-label="Name" value={sku} onChange={(e) => setSku(e.target.value)} />
                            </div>
                            <div className="input-group input-group-sm mb-3 w-100">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Public Price</span>
                                </div>
                                <input type="number" className="form-control" aria-label="Name" value={price} onChange={(e) => setPrice(e.target.value)} />
                            </div>
                            <div className="input-group input-group-sm mb-3 w-100">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Trade Price</span>
                                </div>
                                <input type="number" className="form-control" aria-label="Name" value={retailPrice} onChange={(e) => setRetailPrice(e.target.value)} />
                            </div>
                            <div className="input-group input-group-sm mb-3 w-100">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Public Discount Price</span>
                                </div>
                                <input type="number" className="form-control" aria-label="Name" value={retailDiscountedPrice} onChange={(e) => setRetailDiscountedPrice(e.target.value)} />
                            </div>
                            <div className="input-group input-group-sm mb-3 w-100">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Trade Discount Price</span>
                                </div>
                                <input type="number" className="form-control" aria-label="Name" value={discountPrice} onChange={(e) => setDiscountPice(e.target.value)} />
                            </div>
                            <div className="input-group input-group-sm mb-3 w-100">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Quantity</span>
                                </div>
                                <input type="number" className="form-control" aria-label="Name" value={quantity} onChange={(e) => setQuantity(e.target.value)} />
                            </div>
                            <div className="input-group input-group-sm mb-3 w-100">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Width</span>
                                </div>
                                <input type="text" className="form-control" aria-label="Name" value={width} onChange={(e) => setWidth(e.target.value)} />
                            </div>
                            <div className="input-group input-group-sm mb-3 w-100">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Height</span>
                                </div>
                                <input type="text" className="form-control" aria-label="Name" value={height} onChange={(e) => setHeight(e.target.value)} />
                            </div>
                            <div className="input-group input-group-sm mb-3 w-100">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Length</span>
                                </div>
                                <input type="text" className="form-control" aria-label="Name" value={length} onChange={(e) => setLength(e.target.value)} />
                            </div>
                            <div className="input-group input-group-sm mb-3 w-100">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Weight</span>
                                </div>
                                <input type="text" className="form-control" aria-label="Name" value={weight} onChange={(e) => setWeight(e.target.value)} />
                            </div>
                            <div className="input-group input-group-sm mb-3 w-500" >
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Product Status</span>
                                    <select id="myDropdown" style={{ width: "275px" }} onChange={(e) => setProductStatus(e.target.value)}>
                                        <option value={productStatus}>{productStatus != null ? productStatus : <>Select Option</>}</option>
                                        {productStatusList.length > 0
                                            ? productStatusList
                                                .filter(item => item !== productStatus) // Exclude the currently selected catId
                                                .map(item => (
                                                    <option key={item} value={item}>
                                                        {item}
                                                    </option>
                                                ))
                                            : <option>N/A</option>
                                        }
                                    </select>
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-around', fontFamily: 'sans-serif', fontSize: '15px !important' }}>
                                {productStatus == 'in-stock' ? <label>
                                    <input
                                        type="checkbox"
                                        checked={checkBoxes.unlimited}
                                        onChange={() => handleCheckBoxChange('unlimited')}
                                    />
                                    Unlimited Stock
                                </label> : <></>}
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={checkBoxes.backOrder}
                                        onChange={() => handleCheckBoxChange('backOrder')}
                                    />
                                    Allow Backorder
                                </label>



                                {/* <label>
                                <input
                                    type="checkbox"
                                    checked={checkBoxes.checkBox3}
                                    onChange={() => handleCheckBoxChange('checkBox3')}
                                />
                                Check Box 3
                            </label> */}
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-around', fontFamily: 'sans-serif', fontSize: '15px !important' }}>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={checkBoxes.arrival}
                                        onChange={() => handleCheckBoxChange('arrival')}
                                    />
                                    New Arrival
                                </label>

                                <label>
                                    <input
                                        type="checkbox"
                                        checked={checkBoxes.promotion}
                                        onChange={() => handleCheckBoxChange('promotion')}
                                    />
                                    Promotion
                                </label>

                                {/* <label>
                                <input
                                    type="checkbox"
                                    checked={checkBoxes.checkBox3}
                                    onChange={() => handleCheckBoxChange('checkBox3')}
                                />
                                Check Box 3
                            </label> */}
                            </div>
                            {/* <div className="form-check" style={{ marginBottom: "10px" }}>
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="toggleSwitch"
                                    checked={isAvailable}
                                    onChange={handleToggleAvailable}
                                />
                                <label className="form-check-label" htmlFor="toggleSwitch" style={{ fontFamily: 'sans-serif', fontSize: '15px !important', marginBottom: "2px" }}>In Stock</label>
                            </div> */}
                            <button className="excel-btn col-reds w-10 pt-1 pb-1" type="submit" onClick={createTutorial}>Add</button>
                        </div>
                    </> : modalType == "edit" ? <>
                        <p className="pass-text">Update Product</p>
                        <button onClick={closeModal} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        <div className="modal-body">
                            {/* <div className="form-check" style={{ marginBottom: "10px" }}>
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="toggleSwitch"
                                    checked={isAvailable}
                                    onChange={handleToggleAvailable}
                                />
                                <label className="form-check-label" htmlFor="toggleSwitch" style={{ fontFamily: 'sans-serif', fontSize: '15px !important', marginBottom: "2px" }}>In Stock</label>
                            </div> */}

                            <div className="input-group input-group-sm mb-3 w-100">
                                <input
                                    type="file"
                                    className="form-control uploader"
                                    placeholder='Upload image'
                                    name="attachment[]"
                                    multiple
                                    accept="image/png, image/jpeg"
                                    id="upload-img"
                                    onChange={handleImageUpload}
                                />
                            </div>
                            <section>
                                <div className="row" style={{ display: "inline" }}>
                                    <div
                                        className="col-lg-4 col-md-4 col-sm-6 col-12"
                                        style={{ display: "inline" }}
                                    >
                                        <div className="userCard" style={{ display: "inline" }}>
                                            <div style={{ display: "inline" }}>
                                                {prev_picture && prev_picture?.length > 0 &&
                                                    prev_picture?.map((image, index) => (
                                                        <>
                                                            <div
                                                                style={{
                                                                    display: "inline",
                                                                    position: "relative",
                                                                }}
                                                                key={index}
                                                            >
                                                                <img
                                                                    // src={`${process.env.REACT_APP_IMAGEURL}${image}`}
                                                                    src={
                                                                        image.startsWith("http") || image.startsWith("https")
                                                                            ? image
                                                                            : `${process.env.REACT_APP_IMAGEURL}${image}`
                                                                    }
                                                                    alt={`Product Image ${index + 1}`}
                                                                    style={{
                                                                        width: "100px",
                                                                        height: "100px",
                                                                        borderRadius: "20px",
                                                                        marginBottom: "10px",
                                                                        marginRight: "10px",
                                                                        marginLeft: "10px",
                                                                    }}
                                                                />
                                                                <button
                                                                    style={{
                                                                        borderRadius: "100%",
                                                                        position: "absolute",
                                                                        right: "20px",
                                                                        marginTop: "2px",
                                                                    }}
                                                                    onClick={(e) =>
                                                                        handleImageRemovePrev(e, index)
                                                                    }
                                                                >
                                                                    <i
                                                                        style={{
                                                                            color: "red",
                                                                            backgroundColor: "white",
                                                                        }}
                                                                        className="fas fa-trash-alt"
                                                                    ></i>
                                                                </button>
                                                            </div>
                                                        </>
                                                    ))}
                                            </div>
                                            <div style={{ display: "inline" }}>
                                                {productImage?.map((image, index) => (
                                                    <>
                                                        <div
                                                            style={{
                                                                display: "inline",
                                                                position: "relative",
                                                            }}
                                                            key={index}
                                                        >
                                                            <img
                                                                src={URL.createObjectURL(image)}
                                                                alt={`Image ${index}`}
                                                                style={{
                                                                    width: "100px",
                                                                    height: "100px",
                                                                    borderRadius: "20px",
                                                                    marginBottom: "10px",
                                                                    marginRight: "10px",
                                                                    marginLeft: "10px",
                                                                }}
                                                            />
                                                            <button
                                                                style={{
                                                                    borderRadius: "100%",
                                                                    position: "absolute",
                                                                    right: "20px",
                                                                    marginTop: "2px",
                                                                }}
                                                                onClick={(e) => handleImageRemove(e, index)}
                                                            >
                                                                <i
                                                                    style={{
                                                                        color: "red",
                                                                        backgroundColor: "white",
                                                                    }}
                                                                    className="fas fa-trash-alt"
                                                                ></i>
                                                            </button>
                                                        </div>
                                                    </>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </section>
                            {/* <div>
                                <div className="input-group input-group-sm mb-3 w-100">
                                    <input
                                        type="file"
                                        className="form-control uploader"
                                        placeholder='Upload image'
                                        name="attachment[]"
                                        multiple
                                        accept="image/png, image/jpeg"
                                        id="upload-img"
                                        onChange={handleFileChange}
                                    />
                                </div>
                                {attachments.length > 0 && (
                                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom : "10px" }}>
                                        {attachments.map((file, index) => (
                                            <div key={index} style={{ marginRight: '10px' }}>

                                                <img
                                                    src={URL.createObjectURL(file)}
                                                    alt={`Image ${index}`}
                                                    height={"50px"}
                                                    width={"50px"}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div> */}
                            <div className="input-group input-group-sm mb-3 w-100">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Title</span>
                                </div>
                                <input type="text" className="form-control" aria-label="Name" value={title} onChange={(e) => setTitle(e.target.value)} />
                            </div>
                            <div className="input-group input-group-sm mb-3 w-500" style={{ width: "350px" }}>
                                <div className="input-group-prepend">

                                    <select id="myDropdown" style={{ width: "380px" }} onChange={(e) => setSubCatId(e.target.value)}>
                                        <option value={subCatId}>{subCatName}</option>
                                        {subcat.length > 0
                                            ? subcat
                                                .filter(item => item._id !== subCatId) // Exclude the currently selected catId
                                                .map(item => (
                                                    <option key={item._id} value={item._id}>
                                                        {item.name}
                                                    </option>
                                                ))
                                            : <option>N/A</option>
                                        }
                                    </select>
                                    {/* <option value="">Select Sub Category</option>
                                        {subcat.length > 0  ? subcat.map(item => (
                                            <option key={item._id} value={item._id}>
                                                {item.name}
                                            </option>
                                        )) : <option>N/A</option>}
                                    </select> */}
                                </div>
                            </div>
                            <div className="input-group input-group-sm mb-3 w-100">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Description</span>
                                </div>
                                <textarea type="text" className="form-control" aria-label="Name" value={description} onChange={(e) => setDescription(e.target.value)} />
                            </div>
                            <div className="input-group input-group-sm mb-3 w-100">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">SKU</span>
                                </div>
                                <input type="text" className="form-control" aria-label="Name" value={sku} onChange={(e) => setSku(e.target.value)} />
                            </div>
                            <div className="input-group input-group-sm mb-3 w-100">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Public Price</span>
                                </div>
                                <input type="number" className="form-control" aria-label="Name" value={price} onChange={(e) => setPrice(e.target.value)} />
                            </div>
                            <div className="input-group input-group-sm mb-3 w-100">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Trade Price</span>
                                </div>
                                <input type="number" className="form-control" aria-label="Name" value={retailPrice} onChange={(e) => setRetailPrice(e.target.value)} />
                            </div>

                            <div className="input-group input-group-sm mb-3 w-100">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Quantity</span>
                                </div>
                                <input type="number" className="form-control" aria-label="Name" value={quantity} onChange={(e) => setQuantity(e.target.value)} />
                            </div>
                            <div className="form-check" style={{ marginBottom: "10px" }}>
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="toggleSwitch"
                                    checked={isDiscountActive}
                                    onChange={handleToggle}
                                />
                                <label className="form-check-label" htmlFor="toggleSwitch" style={{ fontFamily: 'sans-serif', fontSize: '15px !important' }}>Activate Discount</label>
                            </div>
                            {isDiscountActive == true ? <>      <div className="input-group input-group-sm mb-3 w-100">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Public Discount Price</span>
                                </div>
                                <input type="number" className="form-control" aria-label="Name" value={discountPrice} onChange={(e) => setDiscountPice(e.target.value)} />
                            </div></> : <></>}
                            <div className="form-check" style={{ marginBottom: "10px" }}>
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="toggleSwitch"
                                    checked={isRetailDiscountActive}
                                    onChange={handleToggleReatail}
                                />
                                <label className="form-check-label" htmlFor="toggleSwitch" style={{ fontFamily: 'sans-serif', fontSize: '15px !important' }}>Activate Trade Discount</label>
                            </div>
                            {isRetailDiscountActive == true ? <>       <div className="input-group input-group-sm mb-3 w-100">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Trade Discount Price</span>
                                </div>
                                <input type="number" className="form-control" aria-label="Name" value={retailDiscountedPrice} onChange={(e) => setRetailDiscountedPrice(e.target.value)} />
                            </div> </> : <></>}
                            <div className="input-group input-group-sm mb-3 w-100">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Width</span>
                                </div>
                                <input type="text" className="form-control" aria-label="Name" value={width} onChange={(e) => setWidth(e.target.value)} />
                            </div>
                            <div className="input-group input-group-sm mb-3 w-100">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Height</span>
                                </div>
                                <input type="text" className="form-control" aria-label="Name" value={height} onChange={(e) => setHeight(e.target.value)} />
                            </div>
                            <div className="input-group input-group-sm mb-3 w-100">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Length</span>
                                </div>
                                <input type="text" className="form-control" aria-label="Name" value={length} onChange={(e) => setLength(e.target.value)} />
                            </div>
                            <div className="input-group input-group-sm mb-3 w-100">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Weight</span>
                                </div>
                                <input type="text" className="form-control" aria-label="Name" value={weight} onChange={(e) => setWeight(e.target.value)} />
                            </div>
                            <div className="input-group input-group-sm mb-3 w-500" >
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Product Status</span>
                                    <select id="myDropdown" style={{ width: "275px" }} onChange={(e) => setProductStatus(e.target.value)}>
                                        <option value={productStatus}>{productStatus != null ? productStatus : <>Select Option</>}</option>
                                        {productStatusList?.length > 0
                                            ? productStatusList
                                                .filter(item => item !== productStatus) // Exclude the currently selected catId
                                                .map(item => (
                                                    <option key={item} value={item}>
                                                        {item}
                                                    </option>
                                                ))
                                            : <option>N/A</option>
                                        }
                                    </select>
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-around', fontFamily: 'sans-serif', fontSize: '15px !important' }}>
                                {productStatus == 'in-stock' ? <label>
                                    <input
                                        type="checkbox"
                                        checked={checkBoxes.unlimited}
                                        onChange={() => handleCheckBoxChange('unlimited')}
                                    />
                                    Unlimited Stock
                                </label> : <></>}
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={checkBoxes.backOrder}
                                        onChange={() => handleCheckBoxChange('backOrder')}
                                    />
                                    Allow Backorder
                                </label>



                                {/* <label>
                                <input
                                    type="checkbox"
                                    checked={checkBoxes.checkBox3}
                                    onChange={() => handleCheckBoxChange('checkBox3')}
                                />
                                Check Box 3
                            </label> */}
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-around', fontFamily: 'sans-serif', fontSize: '15px !important' }}>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={checkBoxes.arrival}
                                        onChange={() => handleCheckBoxChange('arrival')}
                                    />
                                    New Arrival
                                </label>

                                <label>
                                    <input
                                        type="checkbox"
                                        checked={checkBoxes.promotion}
                                        onChange={() => handleCheckBoxChange('promotion')}
                                    />
                                    Promotion
                                </label>

                                {/* <label>
                                <input
                                    type="checkbox"
                                    checked={checkBoxes.checkBox3}
                                    onChange={() => handleCheckBoxChange('checkBox3')}
                                />
                                Check Box 3
                            </label> */}
                            </div>

                            <button className="excel-btn col-reds w-10 pt-1 pb-1" type="submit" onClick={productUpdate}>Update</button>
                        </div>
                    </> : <></>}
                </div>
            </Modal >
            <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginTop: users ? "3%" : "12%"
            }}>
                <section className="excel-sec">
                    <div className="container tableContainer">
                        <div className="  mt-2 mb-3" >
                            <button className="excel-btn col-reds w-10 pt-1 pb-1" onClick={() => viewModal(null, "create")}>
                                Add Product
                            </button>

                        </div>
                    </div>
                </section>
                <section className="coupon-sec-2">
                    <div className="container tableContainer">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="card shadow mb-4">
                                    <div className="card-body">
                                        <div className="table-responsive" id="tableready">
                                            <table id="tableData" className="table table-bordered" style={{ width: '100%', textAlign: "center" }}>
                                                <thead>
                                                    {users ? (<tr>
                                                        <th>S.No</th>
                                                        <th>Title</th>
                                                        <th>Sub Category Name</th>
                                                        <th>Description</th>
                                                        <th>Quantity</th>
                                                        <th>Public Price</th>
                                                        <th>Trade Price</th>
                                                        <th>SKU</th>
                                                        <th>Status</th>
                                                        <th>Creation date</th>
                                                        {/* <th>Detail</th> */}
                                                        <th>Action</th>
                                                    </tr>) : (<tr></tr>)}
                                                </thead>
                                                <tbody >
                                                    {users?.map((item, i) => (
                                                        <tr key={i}>
                                                            <td>{i + 1}</td>
                                                            <td>{item?.title ? item?.title : <>N/A</>}</td>
                                                            <td>{item?.category ? item?.category?.name : <>N/A</>}</td>
                                                            <td style={{
                                                                wordWrap: "break-word",
                                                                wordBreak: "break-all",
                                                                whiteSpace: "normal",

                                                            }}>{item?.description ? item?.description : <>N/A</>}</td>
                                                            <td>{item?.quantity ? item?.quantity : <>N/A</>}</td>
                                                            <td>{item?.price ? item?.price : <>N/A</>}</td>
                                                            <td>{item?.retailer_price ? item?.retailer_price : <>N/A</>}</td>
                                                            <td>{item?.sku ? item?.sku : <>N/A</>}</td>
                                                            <td>{item?.product_status ? item?.product_status : <>N/A</>} </td>
                                                            <td>{moment(item?.createdAt ? item?.createdAt : <>N/A</>).format("DD-MMM-YYYY")}</td>
                                                            {/* <td>
                                                                <span className="edit-icon" >
                                                                    <span style={{ cursor: "pointer", fontWeight: "bold", margin: 10, fontSize: 13, }} onClick={() => viewModal(item, "userDetail")}  ><i className="fas fa-eye"></i> View</span>
                                                                </span>
                                                            </td> */}
                                                            <td>
                                                                <span className="edit-icon" >
                                                                    <span style={{ cursor: "pointer", fontWeight: "bold", margin: 10 }} onClick={() => viewModal(item, "edit")}  ><i className="fas fa-edit"></i></span>
                                                                    <span style={{ cursor: "pointer", fontWeight: "bold", margin: 10 }} onClick={() => viewModal(item?._id, "delete")}  ><i className="fas fa-trash-alt"></i></span>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    )
                                                    )}
                                                </tbody>
                                                <tfoot>
                                                    {users ? (<tr>
                                                        <th>S.No</th>
                                                        <th>Title</th>
                                                        <th>Sub Category Name</th>
                                                        <th>Description</th>
                                                        <th>Quantity</th>
                                                        <th> Public Price</th>
                                                        <th>Trade Price</th>
                                                        <th>SKU</th>
                                                        <th>Status</th>
                                                        <th>Creation date</th>
                                                        {/* <th>Detail</th> */}
                                                        <th>Action</th>
                                                    </tr>) : (<tr><th>{status == "loading" ? "Loading..." : "No Products Found"}</th></tr>)}
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
        </>
    )
}
export default ProductList
